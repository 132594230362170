import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {   MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, mergeMap } from 'rxjs/operators';
import { CompanyDetailsService } from 'src/app/core/services/company-details';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { ICompanyDetails } from '../ICompanyDetails';
import { ISelectedCompany } from '../ICurrentUser';
import { ISwitchCompanySetting } from '../ISwitchCompanySetting';

@Component({
  selector: 'company-detail-panel',
  templateUrl: './company-detail-panel.component.html',
  styleUrls: ['./company-detail-panel.component.scss'],
})
export class CompanyDetailPanelComponent implements OnInit {
  public primaryCompanyName: string;
  public primaryCompanyshortName: string;
  public companiesDetails: Array<ICompanyDetails>;
  public companyId : number;
  public selectedCompanyName: string;

  @ViewChild('companiesList', { static: true }) private matSelectionList: MatSelectionList;
  @Output() updatedCompanyId = new EventEmitter<number>();



  constructor(
    private userService: UserStateService,
    private companyDetailsService: CompanyDetailsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ref: ChangeDetectorRef,
  ) {

    this.userService.AddedNewCompany.subscribe((added: Boolean) => { if(added) this.loadCompaniesList(); });
    
  }

  private setPrimaryCompanyName(userService: UserStateService) {

 
      this.primaryCompanyName = userService.PrimaryCompany.Name;
      this.primaryCompanyshortName = userService.PrimaryCompany.Name.substring(0, 1).toUpperCase();
      this.selectedCompanyName = userService.companyName;

  }

  public applyFormat(companyName: string) {

    return companyName.length > 15 ? companyName.substring(0, 15) + '...' : companyName;
  }

  ngOnInit(): void {

    
    this.setPrimaryCompanyName(this.userService);
    this.loadCompaniesList();
 
  }

  loadCompaniesList()
  {
    this.companyDetailsService.getCompaniesListByUserId(this.userService.userDetailId)
    .subscribe((companiesDetails: Array<ICompanyDetails>) => {
      this.companiesDetails = companiesDetails;
      this.ref.detectChanges();
    });
  }

  switchCompany(company: ICompanyDetails): void {


    let switchCompanySetting = {
      FromCompanyUserAccessIdId: this.userService.companyUserAccessId,
      ToCompanyUserAccessId: company.CompanyUserAccessId,
      UserDetailId: this.userService.userDetailId,

    } as ISwitchCompanySetting;


    this.companyDetailsService.updateSwitchCompany(switchCompanySetting)
      .pipe(mergeMap(_ => this.userService.updateSelectedCompany(this.userService.userDetailId)))
      .pipe(mergeMap(_ => this.companyDetailsService.getCompaniesListByUserId(this.userService.userDetailId)))
      .subscribe((companiesDetails: Array<ICompanyDetails>) => {
 
        this.matSelectionList.deselectAll();
        this.ref.detectChanges();
        this.setPrimaryCompanyName(this.userService);
        this.updatedCompanyId.emit(this.userService.currentUser.SelectedCompany.CompanyId);

        this.router.navigate(['company/'+this.userService.currentUser.SelectedCompany.CompanyId+'/settings/selectedcompany'])
      });

  }
}
