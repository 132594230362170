import { Component, OnInit } from '@angular/core';
import { PanelService } from 'src/app/core/services/panel.service';

@Component({
  selector: 'app-lets-tak-together',
  templateUrl: './lets-tak-together.component.html',
  styleUrls: ['./lets-tak-together.component.scss']
})
export class LetsTakTogetherComponent implements OnInit {

  constructor(public sidenavService: PanelService) { }

  ngOnInit(): void {
  }

}
