import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learn-how-to-sell',
  templateUrl: './learn-how-to-sell.component.html',
  styleUrls: ['./learn-how-to-sell.component.scss']
})
export class LearnHowToSellComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
