<div fxLayout="row" fxLayoutAlign="end center">
    <!-- <button mat-stroked-button color="primary" (click)="addCorporateForm()" >Add New</button> -->
    <button mat-stroked-button color="primary" (click)="addActionTracker()"   >Add New Action</button>
 </div>
        
         <table mat-table [dataSource]="actionTrackerDataSource" >
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    {{element.Action}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Area">
                <th mat-header-cell *matHeaderCellDef> Area </th>
                <td mat-cell *matCellDef="let element">
                    {{element.Area}}
                </td>
            </ng-container>
 
             
            <ng-container matColumnDef="ActionResult">
                <th mat-header-cell *matHeaderCellDef> Action Result </th>
                <td mat-cell *matCellDef="let element">
                    {{element.ActionResult}}
                </td>
            </ng-container>
 
           <ng-container matColumnDef="Owner">
                <th mat-header-cell *matHeaderCellDef> Owner </th>
                <td mat-cell *matCellDef="let element">
                    {{element.Owner}}
                </td>
            </ng-container>

             

            <ng-container matColumnDef="Priority">
                <th mat-header-cell *matHeaderCellDef> Priority </th>
                <td mat-cell *matCellDef="let element">
                    {{element.Priority}}
                </td>
            </ng-container>

        
            <ng-container matColumnDef="Progress">
                <th mat-header-cell *matHeaderCellDef> Progress </th>
                <td mat-cell *matCellDef="let element">
                    {{element.Progress}}
                </td>
            </ng-container>

        
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    {{element.Status}}
                </td>
            </ng-container>
             
            <ng-container matColumnDef="DueDate">
                <th mat-header-cell *matHeaderCellDef> Due Date </th>
                <td mat-cell *matCellDef="let element">
                    {{element.DueDate | date}}
                </td>
            </ng-container>

            <ng-container [matColumnDef]="'actions'">
                <th mat-header-cell *matHeaderCellDef> View / Edit </th>
                <td mat-cell *matCellDef="let element; let corporateFormIndex = index">
                     
                    <button type="button" mat-icon-button (click)="deleteActionTracker(element.ActionTrackerId)">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <button type="button" mat-icon-button (click)="editActionTrackerForm(element.ActionTrackerId)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </ng-container> 

            <tr mat-header-row *matHeaderRowDef="displayedColumnActionTracker"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnActionTracker;"></tr>
        </table>
         
 