import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IActionTracker } from 'src/app/shared/CorporateFinanceTool/IActionTracker';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ActionTrackerService {
  
  baseUrl = this.utilitiesService.getApiUrl();
  companyValuationBaseUrl = this.baseUrl + '/ActionTracker';
  

  constructor(private http: HttpClient,  private utilitiesService: UtilitiesService) { }


  addOrUpdateActionTracker(actionTracker : IActionTracker) :  Observable<IActionTracker>
  {
        return this.http.post<IActionTracker>(
        this.companyValuationBaseUrl + '/ActionTracker', actionTracker);

  }

  getActionTracker(companyId : number) :  Observable<Array<IActionTracker>>
  {
        return this.http.get<Array<IActionTracker>>(
        this.companyValuationBaseUrl + '/ActionTracker/' + companyId);
  }

 
  delete(actionTrackerId : number) : Observable<boolean> {
    return this.http.delete<boolean>(
      this.companyValuationBaseUrl + '/ActionTracker/' + actionTrackerId);

  }

}
