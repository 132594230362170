import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {    
    // constructor(@Inject('Window') private window: Window) { }

    // getUrl() {
    //     const port = this.getPort();
    //     return `${this.window.location.protocol}//${this.window.location.hostname}${port}`;
    // }

    getApiUrl()
    {
        return `${environment.apiHost}${environment.port}`;
    }

    // private getPort() {
    //     const port = this.window.location.port;
    //     if (port) {
    //         // for running with Azure Functions local emulator
    //         if (port === '4200') {
    //             // Local run with 'npm run' also started in api folder for Azure Functions
    //             return ':7071'; // for debugging Azure Functions locally
    //         }
    //         // Running with local node (which serves Angular and the API)
    //         return ':' + this.window.location.port;
    //     }
    //     else {
    //         // for running locally with Docker/Kubernetes
    //         if (this.window.location.hostname === 'localhost') {
    //             return ':8080';
    //         }
    //     }
    //     return '';
    // }
}