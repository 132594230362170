import { UserDetailsService } from "./services/user-details.service";
import { NgModule } from '@angular/core';
import { CompanyDetailsService } from "./services/company-details";
import { InformationMemorandumService } from "./services/information-memorandum.service";
import { UploadDownloadService } from "./services/upload-download.service";
import { CompanyValuationService } from "./services/company-valuation.service";
import { SafeHtmlPipeDocument } from "./Pipes/pipe-transform.pipe";
import { TruncateWithEllipsisPipe } from './Pipes/truncate-with-ellipsis.pipe';
import { SafeHtmlPipe } from './Pipes/safe-html.pipe';

@NgModule({
    providers: [
        UserDetailsService,
        CompanyDetailsService,
        InformationMemorandumService,
        UploadDownloadService,
        CompanyValuationService,
        SafeHtmlPipeDocument,
        TruncateWithEllipsisPipe
    ], 
    declarations: [  TruncateWithEllipsisPipe, SafeHtmlPipeDocument, SafeHtmlPipe ],
    exports: [   TruncateWithEllipsisPipe, SafeHtmlPipeDocument ]
  })

  export class PlatformCoreModule{}
  