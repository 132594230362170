import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { RouterModule, Routes } from '@angular/router';
import { DialogAllSetsBuyerForm, DialogAllSetsForm, DialogUserDetailsForm, DialogWelcomeUserType, UserdetailsformComponent } from './userdetailsform/userdetailsform.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WelcomeComponent } from './welcome/welcome.component';
import { PlatformCoreModule } from '../core/platform-core.module';
import { SidenavModule } from '../shared/sidenav.module';
import { OnboardingFormComponent } from './onboarding-form/onboarding-form.component';
import { AddCompanyComponent } from './add-company/add-company.component';

const routes: Routes = [
  { path: '', component: UserdetailsformComponent},
 // { path: '**', redirectTo: 'welcome' }
]

@NgModule({
  declarations: [
    DialogWelcomeUserType,
    UserdetailsformComponent, 
    DialogUserDetailsForm,
    DialogAllSetsForm,
    OnboardingFormComponent,
    DialogAllSetsBuyerForm,
    AddCompanyComponent
  ],
  exports: [
    UserdetailsformComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
    PlatformCoreModule
  ]
})
export class OnboardingModule { }
