import { Component } from '@angular/core';

@Component({
  selector: 'app-secure-chat-gpt',
  templateUrl: './secure-chat-gpt.component.html',
  styleUrls: ['./secure-chat-gpt.component.scss']
})
export class SecureChatGPTComponent {

}
