<div fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button color="primary" (click)="addCorporateForm()">Add New Prospect</button>
</div>
<table mat-table [dataSource]="corporateFinanceDataSource">

     
    <ng-container matColumnDef="CreatedDate">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let element">
            {{element.CreatedDate | date : 'short'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="CompanyName">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell *matCellDef="let element">
            {{element.CompanyName}}
        </td>
    </ng-container>
    <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element">
            {{element.Email}}
        </td>
    </ng-container>
    <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef> First Name </th>
        <td mat-cell *matCellDef="let element">
            {{element.FirstName}}
        </td>
    </ng-container>

    <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let element">
            {{element.LastName}}
        </td>
    </ng-container>

    <ng-container matColumnDef="CompanyWebsite">
        <th mat-header-cell *matHeaderCellDef> CompanyWebsite </th>
        <td mat-cell *matCellDef="let element">
            {{element.CompanyWebsite}}
        </td>
    </ng-container>

    <ng-container matColumnDef="Sector">
        <th mat-header-cell *matHeaderCellDef> Sector </th>
        <td mat-cell *matCellDef="let element">
            {{element.Sector}}
        </td>
    </ng-container>


    <ng-container matColumnDef="Revenue">
        <th mat-header-cell *matHeaderCellDef> Revenue </th>
        <td mat-cell *matCellDef="let element">
            {{element.Revenue}}
        </td>
    </ng-container>



    <ng-container matColumnDef="EBITDA">
        <th mat-header-cell *matHeaderCellDef> EBITDA </th>
        <td mat-cell *matCellDef="let element">
            {{element.EBITDA}}
        </td>
    </ng-container>


    <ng-container matColumnDef="Profit">
        <th mat-header-cell *matHeaderCellDef> Profit </th>
        <td mat-cell *matCellDef="let element">
            {{element.Profit}}
        </td>
    </ng-container>


    <ng-container matColumnDef="PhoneNumber">
        <th mat-header-cell *matHeaderCellDef> Phone number </th>
        <td mat-cell *matCellDef="let element">
            {{element.PhoneNumber}}
        </td>
    </ng-container>

    <ng-container matColumnDef="ProgressState">
        <th mat-header-cell *matHeaderCellDef> Progress State </th>
        <td mat-cell *matCellDef="let element">
            {{element.ProgressState}}
        </td>
    </ng-container>


    <ng-container matColumnDef="MADPContactOwner">
        <th mat-header-cell *matHeaderCellDef> MADP Contact Owner </th>
        <td mat-cell *matCellDef="let element">
            {{element.MADPContactOwner}}
        </td>
    </ng-container>


    <ng-container matColumnDef="PartnerOwner">
        <th mat-header-cell *matHeaderCellDef> Partner Owner</th>
        <td mat-cell *matCellDef="let element">
            {{element.PartnerOwner}}
        </td>
    </ng-container>



    <ng-container matColumnDef="LifecycleStage">
        <th mat-header-cell *matHeaderCellDef> Lifecycle Stage</th>
        <td mat-cell *matCellDef="let element">
            {{element.LifecycleStage}}
        </td>
    </ng-container>


    <ng-container matColumnDef="LeadSource">
        <th mat-header-cell *matHeaderCellDef> Lead Source</th>
        <td mat-cell *matCellDef="let element">
            {{element.LeadSource}}
        </td>
    </ng-container>



    <ng-container [matColumnDef]="'actions'">
        <th mat-header-cell *matHeaderCellDef>Actions</th>

        <td mat-cell *matCellDef="let element; let corporateFormIndex = index">
            <button type="button" matTooltip="Delete Record" mat-icon-button (click)="deleteCorporateFormDialog(element.CorporateFinanceFormId)">
                <mat-icon>delete</mat-icon>
            </button>
            <button *ngIf="!element.IsAdded" matTooltip="Add Company To Platform" type="button" mat-icon-button (click)="addCompanyToPlatformDialog(element.CorporateFinanceFormId)">
                <mat-icon>library_add</mat-icon>
            </button>
            
            <button type="button" matTooltip="View Selected Record" mat-icon-button (click)="editCorporateForm(element.CorporateFinanceFormId)">
                <mat-icon>pageview</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="'freeReport'">
        <th mat-header-cell *matHeaderCellDef> Free Report </th>
        <td mat-cell *matCellDef="let element; let corporateFormIndex = index">
            <button *ngIf="element.ThreeSixtyReportId" type="button" mat-icon-button (click)="openCorporateForm(element.CorporateFinanceFormId)">
                <mat-icon>summarize</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnCorporateFinanceForm"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnCorporateFinanceForm;"></tr>
</table>