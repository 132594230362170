import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICorporateFinanceForm } from 'src/app/shared/CorporateFinanceTool/ICorporateFinanceForm';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CorporateFinanceToolService {

  baseUrl = this.utilitiesService.getApiUrl();
  companyValuationBaseUrl = this.baseUrl + '/CorporateFinanceTool';
  

  constructor(private http: HttpClient,  private utilitiesService: UtilitiesService) { }


  addCorporateFinance(corporateFinanceForm : ICorporateFinanceForm) :  Observable<ICorporateFinanceForm>
  {
        return this.http.post<ICorporateFinanceForm>(
        this.companyValuationBaseUrl + '/CorporateFinanceForm', corporateFinanceForm);

  }


  getCorporateFinance(applicationId : number) :  Observable<Array<ICorporateFinanceForm>>
  {
        return this.http.get<Array<ICorporateFinanceForm>>(
        this.companyValuationBaseUrl + '/CorporateFinanceForms/' + applicationId);
  }

  delete(corporateFinanceFormId : number) : Observable<boolean> {
    return this.http.delete<boolean>(
      this.companyValuationBaseUrl + '/CorporateFinanceForms/' + corporateFinanceFormId);

  }

  updateCorporateFinance(corporateFinanceForm : ICorporateFinanceForm) :  Observable<boolean>
  {
        return this.http.patch<boolean>(
        this.companyValuationBaseUrl + '/CorporateFinanceForm', corporateFinanceForm);

  }

  AssignCompanyToMyPrimparyCompany(corporateFinanceForm : ICorporateFinanceForm) :  Observable<ICorporateFinanceForm>
  {

      return this.http.patch<ICorporateFinanceForm>(
        this.companyValuationBaseUrl + '/AssignCompanyToMyPrimparyCompany', corporateFinanceForm);

  }

}
