export class CompanySettingConfig
{
    public static Juridictions : Array<string> = ["Austria", "Belgium", "Bulgaria", "Croatia", "Republic of Cyprus",
    "Czech Republic", "Denmark", "England and Wales", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Hong Kong", "Ireland",
    "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Romania",
    "Iceland", "Liechtenstein", "Norway", "England and Wales", "Republic of Ireland", "United States", "Singapore","Virgin Islands",];

    public static Currencies : Array<{code: string, name:string }> = [
    {code:"GBP",name:"GBP - Pound Sterling"},
    {code:"EUR",name:"EUR - Euro"},
    {code:"USD",name:"USD - United States Dollar"},
    {code:"AUD",name:"AUD - Australian Dollar"},
    {code:"CAD",name:"CAD - Canadian Dollar"},
    {code:"SGD",name:"SGD - Singapore Dollar"},
    {code:"HKD",name:"HKD - Hong Kong Dollar"},
    {code:"JPY",name:"JPY - Japanese Yen"},
    {code:"DKK",name:"DKK - Danish krone"},
    {code:"SEK",name:"SEK - Swedish Krona"},
    {code:"NOK",name:"NOK - Norwegian Krone"},
    {code:"PLN",name:"PLN - Polish Złoty"},
    {code:"MXN",name:"MXN - Mexican Peso"},
    {code:"ZAR",name:"ZAR - South African Rand"},
    {code:"CHF",name:"CHF - Swiss franc"},
    ];

    public static Types : Array<string> =
    [
        "LTD - Private company limited by shares",
        "LIMITED - Private company limited by shares",
        "LLP - Limited liability partnership",
        "LP - Limited partnership",
        "PLC - Public limited company",
        "LBG - Private company limited by guarantee",
        "PUC - Private unlimited company",
        "CIC - Community interest company",
        "Other",
    ];

    public static PhaseOfGrowthBusiness :  Array<{name: string, discountRate : number }> = [
        { name: "Idea", discountRate: 0.85 },
        { name: "Development", discountRate: 0.60 },
        { name: "Startup", discountRate: 0.50 },
        { name: "Expansion", discountRate: 0.35 },
        { name: "Established", discountRate: 0.25 }
      //  { name: "Established (Precision clients only)", discountRate: 0.3199 }
    ];

    public static TerminalGrowthList : Array<{currencyCode : string, TerminalRate : number}> =
     [
        {currencyCode:"GBP", TerminalRate : 0.05 },
        {currencyCode:"EUR", TerminalRate : 0.0425 },
        {currencyCode:"USD", TerminalRate : 0.055 },
        {currencyCode:"AUD", TerminalRate : 0.041  },
        {currencyCode:"CAD", TerminalRate : 0.05 },
        {currencyCode:"SGD", TerminalRate :  0.035 },
        {currencyCode:"HKD", TerminalRate : 0.057 },
        {currencyCode:"JPY", TerminalRate : 0.0040 },
        {currencyCode:"DKK", TerminalRate : 0.0335 },
        {currencyCode:"SEK", TerminalRate : 0.03750  },
        {currencyCode:"NOK", TerminalRate : 0.03750},
        {currencyCode:"PLN", TerminalRate : 0.0675 },
        {currencyCode:"MXN", TerminalRate : 0.11250 },
        {currencyCode:"ZAR", TerminalRate : 0.08250},
        {currencyCode:"CHF", TerminalRate : 0.01750},

     ];

     public static Reasons : Array<string> =
     [
         "I am Selling",
         "Raising Capital",
         "Buying a Business / Investment Prospect",
         "Validate growth initiatives: Management Report",
         "For a Client - I am an accountant",
         "Divorce",
     ];


     public static ReasonsAndSubmitButton : Array<{ Reason : string, SubmitButtonText : string }> =
     [
         { Reason: "I am Selling", SubmitButtonText: "Email Me My Free Report" },
         { Reason: "Raising Capital", SubmitButtonText: "Email Me My Free Report" },
         { Reason: "I want a Valuation Report", SubmitButtonText: "Email Me My Free Report" },
         { Reason: "Buying a Business or Investment",  SubmitButtonText: "Email Me My Free Report" },
         { Reason: "Validate Growth Initiatives", SubmitButtonText: "Email Me My Free Report" },
         { Reason: "For a Client", SubmitButtonText: "Email Me My Free Report" },
         { Reason: "For a Client - I am an accountant", SubmitButtonText: "Email Me My Free Report" },
         { Reason: "Divorce", SubmitButtonText: "Email Me My Free Report" },
     ];

}
