import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICorporateFinanceForm } from 'src/app/shared/CorporateFinanceTool/ICorporateFinanceForm';
import { ProcessStage } from 'src/app/shared/CorporateFinanceTool/ProcessStages';

@Component({
  selector: 'app-corporate-finance-form-dialog',
  templateUrl: './corporate-finance-form-dialog.component.html',
  styleUrls: ['./corporate-finance-form-dialog.component.scss']
})
export class CorporateFinanceFormDialogComponent implements OnInit {

  public  corporateFianceFg: FormGroup;
  public Stages : string[];
  public LifecycleStageList : Array<string> = ["Subscriber", "Lead", "Marketing Qualified Lead", "Sales Qualified Lead", "Opportunity", "Evangelist", "Other", "Closed Qualified" ];

  constructor(public fb: FormBuilder,
    public dialogRef: MatDialogRef<CorporateFinanceFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public corporateFinanceForm: ICorporateFinanceForm) { }

  ngOnInit(): void {

    this.corporateFianceFg =
    this.fb.group({
      CorporateFinanceFormId: [0, []],
      ApplicationId: [0, []], //company Id
      Email: ['', []],
      FirstName: ['', []],
      LastName: ['', []],
      CompanyName: ['', []],
      ProgressState: ['New Lead', []],
      Notes: ['', []],
      PhoneNumber :  ['', []],
      MADPContactOwner :  ['', []],
      PartnerOwner :  ['', []],
      Sector :  ['', []],
      Revenue :  [ 0, []],
      EBITDA :  [ 0, []],
      Profit :  [0, []],
      LeadSource :  ['', []],
      LifecycleStage :  ['', []],
    });


  this.corporateFianceFg.patchValue(this.corporateFinanceForm);

   this.Stages =  ProcessStage.Stage;

  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {

    var updatedCorporateFinance = this.corporateFianceFg.value as ICorporateFinanceForm;

    Object.assign(this.corporateFinanceForm, updatedCorporateFinance);

    this.dialogRef.close(this.corporateFinanceForm);
  }


}
