import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IIndustryMultiple } from 'src/app/company-valuation/basic-modeling/IIndustryMultiple';
import { ICompanyAdvanceValuation } from 'src/app/shared/CompanyValuation/ICompanyAdvanceMultipleValuation';
import { ICompanyValuationBasic } from 'src/app/shared/CompanyValuation/ICompanyValuationBasic';
import { IContentText as IContentTextSetup } from 'src/app/shared/TextContent/IContentText';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyValuationAIService {

  
  baseUrl = this.utilitiesService.getApiUrl();
  companyValuationBaseUrl = this.baseUrl + '/CompanyValuationAI';

  constructor(private http: HttpClient, private utilitiesService: UtilitiesService) { }


  getAICommentary(companyAdvanceValuation: ICompanyAdvanceValuation): Observable<string>
  {
    return this.http.post<string>(
      this.companyValuationBaseUrl + '/SectorIndustryCommentary', companyAdvanceValuation);
  }

  getAICommentaryBySectorName(sectorName : string)
  {
     
    return this.http.post<string>(
      this.companyValuationBaseUrl + '/SectorIndustryCommentaryBySectorName', sectorName);
  }

  getAICommentaryBySectorIndustry(industryMultiple : IIndustryMultiple)
  {

    return this.http.post<string>(
      this.companyValuationBaseUrl + '/SectorIndustryCommentaryByIndustryMultiple', industryMultiple);
  }

  getSectorIndustryCommentaryByCompanyValuation(companyValuationBasic : ICompanyValuationBasic)
  {
    return this.http.post<string>(
      this.companyValuationBaseUrl + '/SectorIndustryCommentaryByCompanyValuation', companyValuationBasic);
  }

  getGeneratedContentText(contentText : IContentTextSetup)
  {
    return this.http.post<string>(this.companyValuationBaseUrl + '/GeneratedContentText', contentText);
  }

   
  getGeneratedContentByQueryText(contentText : IContentTextSetup)
  {
    return this.http.post<string>(this.companyValuationBaseUrl + '/GeneratedContentByQueryText', contentText);
  }

}
