<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>User Details</span>
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="page-container">
  <form [formGroup]="fgUserDetails" (ngSubmit)="onSubmit()">
    <div fxlayout="column" fxlayoutalign="center stretch" fxLayoutGap="15px">
      <div fxLayout="row" fxFlex fxLayoutGap="15px" fxlayoutalign="start center">

        <div fxLayout="column" fxFlex fxLayoutAlign="space-around stretch" fxlayoutgap="10">
          <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
              <mat-label>Title</mat-label>
              <mat-select required formControlName="Title">
                <mat-option *ngFor="let title of  Titles" [value]="title">
                  {{title}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
              <mat-label>Name</mat-label>
              <input matInput placeholder="" formControlName="Name">
              <mat-hint>Your full legal name, no nicknames or shortened versions</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="1 1 auto">
              <mat-label>Surname</mat-label>
              <input matInput placeholder="" formControlName="Surname">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
            <mat-form-field appearance="fill" fxFlex="1 1 auto">
              <mat-label>Email</mat-label>
              <input matInput placeholder="" formControlName="Email">
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex fxLayoutAlign="space-around stretch" fxlayoutgap="16">
            <div formGroupName="Address">
              <h3 class="mat-h3">Your address, needed for any documents you sign</h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="fill">
                  <mat-label>Line1</mat-label>
                  <input matInput placeholder="" formControlName="Line1">
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Line2</mat-label>
                  <input matInput placeholder="" formControlName="Line2">
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>City</mat-label>
                  <input matInput placeholder="" formControlName="City">
                  <mat-hint>City</mat-hint>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                  <mat-label>Postcode</mat-label>
                  <input matInput placeholder="" formControlName="Postcode">
                </mat-form-field>


                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                  <mat-label>Country</mat-label>
                  <input type="text" placeholder="Select from below or add your own Country" aria-label="Country"
                    matInput formControlName="Country" [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option value="UK">UK</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <h3 class="mat-h3">Phone number</h3>
            <div fxLayout="column" fxLayoutAlign="center stretch">
              <mat-form-field appearance="fill">
                <mat-label>Telephone</mat-label>
                <input type="tel" matInput placeholder="" formControlName="Phone">
                <mat-icon matSuffix>contact_phone</mat-icon>
              </mat-form-field>
            </div>

          </div>

        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
    <h2>Signature<button type="button" mat-button (click)="openSignatureDialog()"><mat-icon aria-hidden="false" aria-label="add new company">create</mat-icon></button></h2>
    <div *ngIf="signatureImg">
      <img class="imageSignature" src='{{ signatureImg }}'  />
    </div>
    <mat-divider></mat-divider>
    <div formGroupName="SocialMediaInformation">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <mat-form-field appearance="fill" flex>
          <mat-label> Your profile</mat-label>
          <input matInput placeholder="" formControlName="Description" />
          <mat-hint>Quick introduction</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" flex>
          <mat-label>TwitterAccount</mat-label>
          <input matInput placeholder="" formControlName="TwitterAccount" />
          <mat-hint>Your LinkedIn profile URL</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" flex>
          <mat-label>LinkedInProfile</mat-label>
          <input matInput placeholder="" formControlName="LinkedInProfile" />
          <mat-hint>Your LinkedIn profile URL</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" flex>
          <mat-label>FacebookPage</mat-label>
          <input matInput placeholder="" formControlName="FacebookPage" />
          <mat-hint>Your Facebook profile URL</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
      <button type="submit" mat-raised-button color="primary" [disabled]="!fgUserDetails.valid">Save changes</button>
    </div>
  </form>
</div>