import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { CorporateFinanceToolService } from '../core/services/corporate-finance-tool.service';
import { UserStateService } from '../core/services/user-state.service';
import { ICorporateFinanceForm } from '../shared/CorporateFinanceTool/ICorporateFinanceForm';

@Injectable({
  providedIn: 'root'
})
export class CorporateFinanceFormResolver implements Resolve<Array<ICorporateFinanceForm>> {

  constructor(
    private userStateService: UserStateService,
    private spinner: NgxSpinnerService,
    private corporateFinanceToolService : CorporateFinanceToolService
    )
    {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<ICorporateFinanceForm>> {
   return  this.corporateFinanceToolService.getCorporateFinance(this.userStateService.selectedCompanyId);
  }
}
