<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Selected Company name: {{ this.companyName}} </span>
        <span class="example-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="page-container">
    <mat-card class="contact-card">
        <mat-card-header>
            <mat-card-title>You are working with company :  {{ this.companyName }} </mat-card-title>
        </mat-card-header>
        <mat-card-content>
       <div> 
        <mat-divider></mat-divider>
            <h3>Select an action from the menu on the left to continue.</h3> 
            <h4>To select different company please use options in left sidebar.</h4>
        </div>
        </mat-card-content>
    </mat-card>

</div>