export class ProcessStage
{
    public static Stage : Array<string> = [
        "New Lead",
        "Report Created",
        "Report Sent",
        "Meeting Booked",
        "Engagement Agreed",
        "Preparation",
        "Marketing",
        "Legal",
        "Closed Lost",
        "Closed Compete"
        ];
}