import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { IThreeSixtyReport } from 'src/app/shared/CorporateFinanceTool/IThreeSixtyReport';


type Links = [url: string, label: string];

@Component({
  selector: 'app-tabs-lead-gen',
  templateUrl: './tabs-lead-gen.component.html',
  styleUrls: ['./tabs-lead-gen.component.scss']
})
export class TabsLeadGenComponent implements OnInit {

  threeSixtyReport: IThreeSixtyReport;
  showTabs : boolean = false;
  
  links: Links[] = [
    ['Prospects', 'Prospects'],
     // ['ContentGenerator', 'Content Generator'],
     //  ['Marketing', 'Marketing'],
    //['Analytics', 'Analytics']
  ];

  activeLink = this.links[0][0];
  CompanyName : string;

  constructor(private activatedRoute: ActivatedRoute,
    private router : Router, private userStateService : UserStateService
     ) 
  {

 
    // this.activatedRoute.data.subscribe( data => 
    // {
    //   if(data.threeSixtyReportsResolver)
    //   {
    //     this.showTabs = true;
    //     this.threeSixtyReport = data.threeSixtyReportsResolver;
    //     this.activeLink = this.links[0][0];
    //   }
    //   else
    //   {
    //     this.showTabs = false;
       
    //   }

    // }
 
    // );

  }

  ngOnInit(): void {
    this.CompanyName = this.userStateService.companyName;
  }

  
  activateTab(tabIndex: number): void
  {
    this.activeLink = this.links[tabIndex][0];
  }


}
