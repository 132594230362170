import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { using } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map,shareReplay, withLatestFrom } from 'rxjs/operators';
import { PanelService } from 'src/app/core/services/panel.service';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { ICompanyDetails } from '../ICompanyDetails';
import { SideNavs, UserTypes, UtilitySetting } from '../UserSetting';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  
  panelOpenState = false;

  public companyId :number;

  @ViewChild('rightPanel', { static: true }) private rightPanel: MatSidenav;
  @ViewChild('drawer', { static: true }) private leftPanel: MatSidenav;
  
  
  constructor(private activatedRoute: ActivatedRoute,
    public userStateService : UserStateService,
    public panelService: PanelService,
    private breakpointObserver: BreakpointObserver,
    private router: Router) 
  {
     
      this.companyId = userStateService.selectedCompanyId;
 

    router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => this.leftPanel.close());
 
  }

  ngOnInit(): void {
    this.panelService.panel = this.rightPanel;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  public setCompanyId(companyId: number) {
    this.companyId = companyId;
 
  }


  public isDisplay(sideNav : SideNavs) : boolean
  {
     var tabs  =  UtilitySetting.Tabs;
     return tabs.filter(m=> m[0] ==this.userStateService.userTypeId )[0][1].some(m=> m == sideNav);
     
  }

  public get SideNavs() {
    return SideNavs; 
  }
 
}
