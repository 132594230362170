import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserdetailsFormComponent } from './userdetails-form/userdetails-form.component';
 

const routes: Routes = [
  { path: 'user-form', component: UserdetailsFormComponent},
]

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
     
  ],
  declarations: [
    UserdetailsFormComponent,
     
  ]
})
export class UserModule { }
