import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs';
import { ActionTrackerService } from 'src/app/core/services/action-tracker.service';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { IActionTracker } from 'src/app/shared/CorporateFinanceTool/IActionTracker';
import { ActionTrackerDialogComponent } from './action-tracker-dialog/action-tracker-dialog.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  
  // public corporateFinanceDataSource = new MatTableDataSource<ICorporateFinanceForm>([]);
  // private corporateFinanceForms = new Array<ICorporateFinanceForm>();

  displayedColumnActionTracker: string[] =
  [
    'Action',
    'Area',
    'ActionResult',
    'Owner',
    'Priority',
    'Progress',
    'Status',
    'DueDate',
    'actions'
  ];

 

  public actionTrackerDataSource = new MatTableDataSource<IActionTracker>([]);
  private actionTrackers = new Array<IActionTracker>();

 
  constructor(private actionTrackerService : ActionTrackerService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private userStateService: UserStateService) 
  {

    this.activatedRoute.data.subscribe((data: any) => {
      this.actionTrackers = data.actionTrackerResolver as Array<IActionTracker>;

    });

  }

  ngOnInit(): void {
    this.actionTrackerDataSource.data = this.actionTrackers;
  }


  deleteActionTracker(actionTrackerId : number)
  {
    this.actionTrackerService.delete(actionTrackerId).subscribe((result : boolean) =>
    {
      if(result)
      {
        this.actionTrackers  = this.actionTrackers.filter(m=> m.ActionTrackerId != actionTrackerId);
        this.actionTrackerDataSource.data = this.actionTrackers;
      }
    }
    );
  }

  openDialog(actionTracker: IActionTracker, isAdd: boolean) {

    const dialogRef = this.dialog.open(ActionTrackerDialogComponent, {
      data: actionTracker,
      position: { top: '30px' },
      width: '60%',
    });


 
    dialogRef.afterClosed().pipe(mergeMap((actionTracker: IActionTracker)=> this.actionTrackerService.addOrUpdateActionTracker(actionTracker)))
    .subscribe((actionTracker: IActionTracker) => 
    {
      if(isAdd){
        this.actionTrackers.unshift(actionTracker);
        this.actionTrackerDataSource.data = this.actionTrackers;
      }
    });


  }

  addActionTracker():void
  {

    var actionTracker : IActionTracker = {} as IActionTracker;
    actionTracker.ActionTrackerId= 0;
    actionTracker.CompanyId = this.userStateService.selectedCompanyId;
    this.openDialog(actionTracker, true)


  }

  editActionTrackerForm(ActionTrackerId : number)
  {
    var selectAdactionTrackers  = this.actionTrackers.find(m => m.ActionTrackerId == ActionTrackerId);
    this.openDialog(selectAdactionTrackers, false);

  }

}
