 
export enum SideNavs
{
    MyTasks = 1,
    MyDocuments = 2,
    ThreeSixtyReports = 3, 
    CompanyValuation = 4, 
    InformationMemorandum = 5,
    DataRoom = 6,
    Transactions = 7,
    PartnerDirectory = 8,
    CapitalRaise = 9,
    Marketplace = 10,
    Dashboard = 11,
    MyAgreements = 12,
    BuyerDictionary = 13,
    Listcompany = 14,
}
  
export enum  UserTypes
{
    Owner = 1, 
    Partners = 2, 
    Brokers = 3 , 
    Buyer = 4
}


export class UtilitySetting
{
    public static Titles : Array<string> = [
    "Dr",
    "Mr",
    "Mrs",
    "Ms",
    "Mx",
    ];

    public static Tabs : Array<[number,Array<SideNavs>]> = [
        [ UserTypes.Owner, [SideNavs.MyTasks,   SideNavs.MyDocuments, SideNavs.MyAgreements, SideNavs.InformationMemorandum, SideNavs.DataRoom, SideNavs.PartnerDirectory, SideNavs.CapitalRaise, SideNavs.Marketplace,  SideNavs.Listcompany ]],
        [ UserTypes.Buyer, [SideNavs.MyTasks,   SideNavs.MyDocuments, SideNavs.MyAgreements, SideNavs.InformationMemorandum, SideNavs.DataRoom, SideNavs.PartnerDirectory, SideNavs.CapitalRaise, SideNavs.Marketplace, SideNavs.Dashboard, SideNavs.Listcompany ]],
        [ UserTypes.Partners, [SideNavs.MyTasks,   SideNavs.MyDocuments, SideNavs.MyAgreements,  SideNavs.CompanyValuation, SideNavs.InformationMemorandum, SideNavs.DataRoom, SideNavs.PartnerDirectory, SideNavs.CapitalRaise, SideNavs.Marketplace, SideNavs.Dashboard, SideNavs.Listcompany ]],
        [ UserTypes.Brokers, [SideNavs.MyTasks,   SideNavs.MyDocuments, SideNavs.MyAgreements, SideNavs.InformationMemorandum, SideNavs.DataRoom, SideNavs.PartnerDirectory, SideNavs.CapitalRaise, SideNavs.Marketplace, SideNavs.Dashboard, SideNavs.Listcompany ]]
    ];

}

 
 
 