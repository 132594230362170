import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-path-options',
  templateUrl: './path-options.component.html',
  styleUrls: ['./path-options.component.scss']
})
export class PathOptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
