import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPreRegistration } from 'src/app/shared/IPreRegistration';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class PreRegisterService {

    
  baseUrl = this.utilitiesService.getApiUrl();
  threeSixtyBaseUrl = this.baseUrl + '/PreRegister';

  constructor(private http: HttpClient, private utilitiesService: UtilitiesService) 
  {
  }

  public CreatePreRegister(preRegister: IPreRegistration): Observable<IPreRegistration> {
    return this.http.post<IPreRegistration>(
      this.threeSixtyBaseUrl + '/CreatePreRegisterLead', preRegister);
    }

}
