import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor( private  Service: MsalService) { }

  ngOnInit(): void {
    this.logout();
  }

  logout() {

    this. Service.logout();

  }

}
