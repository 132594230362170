<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Add Company</span>
        <span class="example-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="page-container">

    <form [formGroup]="fgCompany" (ngSubmit)="onSubmit()">
        <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="start stretch">
            <h1>What is company name?</h1>
            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Company Name</mat-label>
                <input #companyName matInput placeholder="" formControlName="Name" require />
            </mat-form-field>

            <mat-card *ngIf="name.valid" fxFlex="1 1 auto" (click)="onSubmit()" style="cursor: pointer;">
                <mat-card-content>
                    <mat-card-title>Add "{{name.value}}"?</mat-card-title>
                    <mat-card-subtitle>Click to add a new company now.</mat-card-subtitle>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>