<section class="mat-typography">
<h1>Welcome to M&A Deal Platform</h1>
<mat-dialog-content class="mat-typography">
    <h2>Please register to continue</h2>
    <mat-grid-list cols="2" rowHeight="150px"  [gutterSize]="'10px'">
        <mat-grid-tile>
            <button fxFlex="auto" mat-stroked-button color="primary" (click)="setUserType(1)">
                <span class="mat-title">
                    Vendor
                </span>
                <br>
                <span class="subhead">
                    I want to sell a company.</span>
                <span matripple="" class="mat-ripple mat-button-ripple"></span>
                <span class="mat-button-focus-overlay"></span>
                </button>

        </mat-grid-tile>
        <mat-grid-tile>
            <button fxFlex mat-stroked-button color="primary" (click)="setUserType(2)">
                <span class="mat-title">
                   Buyer
                </span>
                <br>
                <span class="subhead">
                    I want to buy a company.</span>
                <span matripple="" class="mat-ripple mat-button-ripple"></span>
                <span class="mat-button-focus-overlay"></span>
                </button>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>
</section>