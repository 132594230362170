import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthGuard } from './auth-guard.guard';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LandingPageBuyersDictionaryComponent } from './buyers-dictionary/landing-page-buyers-dictionary/landing-page-buyers-dictionary.component';
import { LandingPageComponent } from './company-details/landing-page/landing-page.component';
import { HelpComponent } from './help/help.component';
import { LoginComponent } from './login/login.component';
import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { TestComponent } from './test/test.component';
import { UserStateResolver } from './user-state-resolver';
import { SecureChatGPTComponent } from './SecureChatGPT/secure-chat-gpt/secure-chat-gpt.component';

const routes: Routes = [
 
  { path: 'login', component: LoginComponent},
  { path: 'auth', loadChildren:()=> import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  {
    path: "onboarding", loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: '', canActivate: [MsalGuard, AuthGuard], component: SidenavComponent,   
    children: [
      { path: 'company-onboarding', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
      { path: 'my-tasks/:id', loadChildren: () => import('./my-tasks/my-tasks.module').then(m => m.MyTasksModule) },
      { path: 'my-documents/:id', loadChildren: () => import('./my-documents/my-documents.module').then(m => m.MyDocumentsModule) },
      { path: 'transactions/:id', loadChildren: () => import('./transactions/seller-transaction/seller-transaction.module').then(m => m.SellerTransactionModule) },
      { path: 'my-agreements/:id', loadChildren: () => import('./my-agreements/my-agreements.module').then(m => m.MyAgreementsModule) },
      { path: 'buyer-dictionary/:id', loadChildren: () => import('./buyers-dictionary/buyers-dictionary.module').then(m => m.BuyersDictionaryModule) },

      { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
      { path: 'company/:id/information-memorandum', loadChildren: () => import('./company-detailed-view/company-detailed-view.module').then(m => m.CompanyDetailedViewModule) },
      { path: 'company/:id/business-plan', loadChildren: () => import('./business-plan/businessplan.module').then(m => m.BusinessPlanModule) },
       
      { path: 'company/:id/redactedview', loadChildren: () => import('./company-redacted-view/company-redacted-view.module').then(m => m.CompanyRedactedViewModule) },
      { path: 'company/:id/duediligence', loadChildren: () => import('./due-diligence/due-diligence.module').then(m => m.DueDiligenceModule) },
     
      { path: 'company/:id/settings', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
      { path: 'dataroom/:id', loadChildren: () => import('./data-room/data-room.module').then(m => m.DataRoomModule) }, //DataRoomModule
      { path: 'company/:id/valuation', loadChildren : () => import('./company-valuation/company-valuation.module').then(m=> m.CompanyValuationModule) },
      { path: 'company/:id/detail', component : LandingPageComponent},
      { path: 'help', loadChildren :() => import('./help/help.module').then(m => m.HelpModule) },
      { path: 'BespokeValuationReport', redirectTo: 'company/0/valuation/BespokeValuationReport', pathMatch: 'full' },
      { path: 'ThreeSixtyReport', loadChildren: ()=> import('./ThreeSixtyReports/ThreeSixtyReportsModule').then(m=> m.ThreeSixtyReportsModule) },
      { path: 'corporate-lead-gen-list', loadChildren: ()=> import('./corporate-finance-lead-gen/corporate-finance-lead-gen.module').then(m=> m.CorporateFinanceLeadGenModule) },
      { path: 'marketplace', loadChildren: () => import('./marketplace/marketplace.module').then(m=> m.MarketplaceModule) },
      { path: 'listcompany', loadChildren: () => import('./list-company/list-company.module').then(m=> m.ListCompanyModule) },
      { path: 'securechatgpt', component : SecureChatGPTComponent },
      { path: 'ai-copilot', loadChildren: ()=> import('./copilot-ai/copilot-ai.module').then(m=> m.CopilotAIModule)},
      { path: '', redirectTo: 'company/0/settings/selectedcompany', pathMatch: 'full' }
    ]
  },
];

const isIframe = window !== window.parent && !window.opener;

const routerOptions: ExtraOptions = {
  //initialNavigation: !isIframe ? 'enabled' : 'disabled',
  useHash: false,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
