import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICompanyAdvanceValuation } from 'src/app/shared/CompanyValuation/ICompanyAdvanceMultipleValuation';
import { ICompanyValuation } from 'src/app/shared/CompanyValuation/ICompanyValuation';
import { ICompanyValuationBasic } from 'src/app/shared/CompanyValuation/ICompanyValuationBasic';
import { IFinanceDocumentData } from 'src/app/shared/CompanyValuation/IFinancilaDataUpload';
import { IValuationReportResult } from 'src/app/shared/CompanyValuation/Result/IValuationReportResult';
import { ICorporateFinanceForm } from 'src/app/shared/CorporateFinanceTool/ICorporateFinanceForm';
import { UtilitiesService } from './utilities.service';
import { IHistoricalFinancials } from 'src/app/shared/CompanyValuation/IHistoricalFinancials';
import { IForecastFinancials } from 'src/app/shared/CompanyValuation/IForecastFinancials';

@Injectable()
export class CompanyValuationService {

  baseUrl = this.utilitiesService.getApiUrl();
  companyValuationBaseUrl = this.baseUrl + '/CompanyValuation';

  constructor(private http: HttpClient, private utilitiesService: UtilitiesService) { }

  getCompanyValuation(companyDetailsId: number): Observable<ICompanyValuation> {
    return this.http.get<ICompanyValuation>(
      this.companyValuationBaseUrl + '/CompanyValuation' + '/' + companyDetailsId);
  }

  addCompanyValuation(companyValuation: ICompanyValuation): Observable<ICompanyValuation> {
    return this.http.post<ICompanyValuation>(
      this.companyValuationBaseUrl + '/CompanyValuation', companyValuation);
  }

  addOrUpdateCompanyValuationBasic(companyValuationBasic: ICompanyValuationBasic)
  {
    return this.http.put<ICompanyValuationBasic>(
      this.companyValuationBaseUrl + '/CompanyValuationBasic', companyValuationBasic);
  }

  addOrUpdateCompanyAdvancedValuation(companyAdvanceValuation: ICompanyAdvanceValuation): Observable<ICompanyAdvanceValuation>
  {
    return this.http.post<ICompanyAdvanceValuation>(
      this.companyValuationBaseUrl + '/CompanyAdvancedValuation', companyAdvanceValuation);
  }

  addOrUpdateValuationReportResult(valuationReportResult : IValuationReportResult) : Observable<IValuationReportResult>
  {

    return this.http.post<IValuationReportResult>(
      this.companyValuationBaseUrl + '/ValuationReportResult', valuationReportResult);
  }

  addOrUpdateCorporateFinance(corporateFinanceForm : ICorporateFinanceForm) :  Observable<ICorporateFinanceForm>
  {
        return this.http.post<ICorporateFinanceForm>(
        this.companyValuationBaseUrl + '/CorporateFinanceForm', corporateFinanceForm);

  }

  setFinanceData(financeDocumentData : IFinanceDocumentData) : Observable<ICompanyAdvanceValuation>
  {
    return this.http.post<ICompanyAdvanceValuation>(
      this.companyValuationBaseUrl + '/SetFinancialData', financeDocumentData);

  }

  getHistoricalFinancials(historicalFinancialsId: number): Observable<IHistoricalFinancials> {
    return this.http.get<IHistoricalFinancials>(
      this.companyValuationBaseUrl + '/HistoricalFinancials' + '/' + historicalFinancialsId);
  }

  getForecastFinancials(forecastFinancialsId: number): Observable<IForecastFinancials> {
    return this.http.get<IForecastFinancials>(
      this.companyValuationBaseUrl + '/ForecastFinancials' + '/' + forecastFinancialsId);
  }

  
  exportValuationData(financeDocumentData : IFinanceDocumentData): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'Patch',
      this.companyValuationBaseUrl+'/ExportFinancialData',
      financeDocumentData,
      {
        reportProgress: true,
        responseType: 'blob'
      }));

  }
  
}
