import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { concatMap, mapTo, mergeMap } from 'rxjs/operators';
import { CompanyDetailsService } from 'src/app/core/services/company-details';
import { UserDetailsService } from 'src/app/core/services/user-details.service';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { ICompanyDetails } from 'src/app/shared/ICompanyDetails';
import { ICompanyData } from 'src/app/shared/InformationMemorandum/Assets/ICompanyData';
import { IUserDetails } from 'src/app/shared/IUserDetails';
import { UtilitySetting } from 'src/app/shared/UserSetting';
import { UserDetails } from '../models/userDetails';

@Component({
  selector: 'onboarding-userdetailsform',
  templateUrl: './userdetailsform.component.html',
  styleUrls: ['./userdetailsform.component.scss'],
})
export class UserdetailsformComponent implements OnInit {
  fgContactDetails: FormGroup;
  @Output() userOnboarded = new EventEmitter<void>();

  public Titles = UtilitySetting.Titles;

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.spinner.hide();

    let userType: number;

    const dialogUserDetailsRef = this.dialog.open(DialogUserDetailsForm, {
      disableClose: true,
      data: userType
    });

    dialogUserDetailsRef.afterClosed().subscribe((result: IUserDetails) => {
      if (result.UserTypeId == 1) {
        const dialogRef = this.dialog.open(DialogAllSetsForm, {
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((_) => this.userOnboarded.emit());
      }
      else {
        const dialogRef = this.dialog.open(DialogAllSetsBuyerForm, {
          disableClose: true,
        });

      }

    });

  }
}

@Component({
  selector: 'dialog-userdetailsform',
  templateUrl: './dialog-userdetailsform.html',
  styleUrls: ['./userdetailsform.component.scss'],
})
export class DialogUserDetailsForm implements OnInit {
  fgUserDetails: FormGroup;
  public Titles = UtilitySetting.Titles;
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogUserDetailsForm>,
    private userDetailsService: UserDetailsService,
    private userStateService: UserStateService,
    private authService: MsalService,
    private companyDetailsService: CompanyDetailsService,
    @Inject(MAT_DIALOG_DATA) public userType: number) {

    var accountInfo = this.authService.instance.getAllAccounts();
    let emails = accountInfo[0]?.idTokenClaims['emails'];

    let objectId = accountInfo[0].localAccountId;


    this.fgUserDetails = this.fb.group({
      ObjectId: [objectId],
      Title: ['', []],
      Name: ['', [Validators.required]],
      Surname: ['', [Validators.required]],
      Gender: [0, []],
      Email: [emails[0] ?? '', [Validators.required]],
      Address: this.fb.group({
        Line1: ['', []],
        Line2: ['', []],
        City: ['', []],
        Postcode: ['', []],
        Country: ['', []],
      }),
      SocialMediaInformation: this.fb.group({
        Description: ['', []],
        TwitterAccount: ['', []],
        LinkedInProfile: ['', []],
        FacebookPage: ['', []],
      }),
      Phone: ['', []],
      CompanyName: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '30px' });
  }

  onSubmit(): void {
    let userDetails: IUserDetails = this.fgUserDetails.value;

    let companyDetail = {} as ICompanyDetails;
    companyDetail.Name = this.fgUserDetails.get('CompanyName').value;
    // companyDetail.ExecutiveDirectorId = this.userStateService.userDetailId;

     userDetails.UserTypeId = 1;
 
    this.companyDetailsService.addCompanyDetails(companyDetail)
      .pipe(mergeMap((companyId: number) => {
        userDetails.PrimaryCompanyId = companyId;
        userDetails.SelectedCompanyId = companyId;
        return this.userDetailsService.addUserDetails(userDetails);
      }))
      .pipe(mergeMap((userId : number) => this.companyDetailsService.AddToCompaniesList(userId, userDetails.PrimaryCompanyId)))
      .pipe(mergeMap(_ => this.userStateService.populate(userDetails.ObjectId)))
      .subscribe(_ => this.dialogRef.close(userDetails));

  }
}

@Component({
  selector: 'dialog-userdetailsform',
  templateUrl: './dialog-allsetsform.html',
  styleUrls: ['./userdetailsform.component.scss'],
})
export class DialogAllSetsForm {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogAllSetsForm>,
    private userStateService: UserStateService,
  ) { }

  nextPage() {
    this.dialogRef.close();
    this.router.navigateByUrl('');
  }
}

@Component({
  selector: 'dialog-allSetBuyer',
  templateUrl: './dialog-allsets-buyer.html',
  styleUrls: ['./userdetailsform.component.scss'],
})
export class DialogAllSetsBuyerForm {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogAllSetsForm>,
    private userStateService: UserStateService,
  ) { }

  nextPage() {
    this.dialogRef.close();
    this.router.navigateByUrl('');
  }
}


@Component({
  selector: 'dialog-welcome-user-type',
  templateUrl: 'dialog-welcome-user-type.html',
  styleUrls: ['./welcome.component.scss'],
})
export class DialogWelcomeUserType implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogWelcomeUserType>
  ) { }

  ngOnInit() { }

  setUserType(userType: number): void {
    this.dialogRef.close(userType);
  }
}
