import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadGenListingComponent } from './lead-gen-listing/lead-gen-listing.component';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { CorporateFinanceFormResolver } from './corporate-finance-form.resolver';
import { ThreeSixtyReportsModule } from '../ThreeSixtyReports/ThreeSixtyReportsModule';
import { TabsLeadGenComponent } from './tabs-lead-gen/tabs-lead-gen.component';
import { ActionsComponent } from './actions/actions.component';
import { TrainingComponent } from './training/training.component';
import { CorporateFinanceFormDialogComponent } from './lead-gen-listing/corporate-finance-form-dialog/corporate-finance-form-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
 
import { ActionTrackerDialogComponent } from './actions/action-tracker-dialog/action-tracker-dialog.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { ActionTrackerResolver } from './action-tracker.resolver';
import { CoreComponentModule } from '../shared/core-component/core-component.module';
import { ContentGeneratorComponent } from './content-generator/content-generator.component';
import { MarketingComponent } from './marketing/marketing.component';
import { AnalyticComponent } from './analytic/analytic.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

 
const routes: Routes = [
  { path: '', component: TabsLeadGenComponent,
  children: [
    { path: 'Prospects', component: LeadGenListingComponent , resolve : { corporateFinanceFormResolver : CorporateFinanceFormResolver } },
    { path: 'Tracker', component: ActionsComponent, resolve : { actionTrackerResolver : ActionTrackerResolver} },
    { path: 'Training', component: TrainingComponent  },
    { path: 'lead-report', loadChildren: ()=> import('./ThreeSixtyReports/ThreeSixtyReportsModule').then(m=>m.ThreeSixtyReportsModule) },
    { path: 'ContentGenerator', component: ContentGeneratorComponent },
    { path: 'Marketing', component: MarketingComponent},
    { path: 'Analytics', component: AnalyticComponent},
    { path: '', redirectTo: 'Prospects', pathMatch: 'full' } ]
      },
  //{ path: '', component: LeadGenListingComponent , resolve : { corporateFinanceFormResolver : CorporateFinanceFormResolver } },
  //{ path: 'lead-report', loadChildren: ()=> import('./ThreeSixtyReports/ThreeSixtyReportsModule').then(m=>m.ThreeSixtyReportsModule) },
  { path: '**', redirectTo: '' }
]



@NgModule({
  declarations: [
    LeadGenListingComponent,
    TabsLeadGenComponent,
    ActionsComponent,
    TrainingComponent,
    CorporateFinanceFormDialogComponent,
    ActionTrackerDialogComponent,
    ContentGeneratorComponent,
    MarketingComponent,
    AnalyticComponent,
     
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
     
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        CoreComponentModule,
        ClipboardModule,
    RouterModule.forChild(routes),
     
  ],
  exports: 
  [ContentGeneratorComponent]
})
export class CorporateFinanceLeadGenModule { }
