<div fxLayout="column" ffxlayoutalign="start stretch" class="container" fxLayoutGap="16px">
    <span class="title">Grow</span>
    <span class="text">
        <p>You should sell – you're in a place in your life, or circumstances have dictated that selling the business will provide the freedom you need. 
            However, it may not be possible in the short term at least, without managing your price expectations. This is subject to review of your financials and wider market research to confirm what the value of the business may be.
            If waiting a period of up to 24 months is an option for you, we can help you implement a growth strategy to obtain a strategic price for your business.</p>
    </span>
    
   
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutAlign="start stretch"
        fxLayoutGap="15px" style="margin-bottom: 45px;">

        <mat-card class="detail-teaser-card">
            <mat-card-header>
                <mat-card-title>Schedule a Call</mat-card-title>
                <!-- <mat-card-subtitle>Company Reference</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" style="width: 270px;">
                    <div fxLayout="column">
                        <ul>
                            <li>Schedule a 15 or 30 minute call with one of our experts.</li>

                        </ul>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                    <a  mat-flat-button color="primary" href="https://www.madealplatform.com/meetings/id/sales"
                    target="_blank">Schedule a Call</a>  
                </div>
            </mat-card-actions>
        </mat-card>





    </div>
</div>