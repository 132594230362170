import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IFileUploadInformationMemorandum } from 'src/app/shared/IFileUploadInformationMemorandum';
import { IAsset } from 'src/app/shared/InformationMemorandum/Assets/IAssets';
import { IFinance } from 'src/app/shared/InformationMemorandum/Finance/IFinance';
import { IExecutiveSummary } from 'src/app/shared/InformationMemorandum/IExecutiveSummary';
import { IInformationMemorandum } from 'src/app/shared/InformationMemorandum/InformationMemorandum';
import { IInformationMemorandumSummary } from 'src/app/shared/InformationMemorandum/InformationMemorandumSummary/IIinformationMemorandumSummary';
import { IProductAndMarket } from 'src/app/shared/InformationMemorandum/IProductAndMarket';
import { IPreview } from 'src/app/shared/InformationMemorandum/Preview/IPreview';
import { IProspect } from 'src/app/shared/InformationMemorandum/Prospect/IProspect';
import { IStaff } from 'src/app/shared/InformationMemorandum/Staff/IStaff';
import { UtilitiesService } from './utilities.service';
import { IHistoricalFinancials } from 'src/app/shared/CompanyValuation/IHistoricalFinancials';
import { IForecastFinancials } from 'src/app/shared/CompanyValuation/IForecastFinancials';

@Injectable()
export class InformationMemorandumService {
  baseUrl = this.utilitiesService.getApiUrl();
  informationMemorandumBaseUrl = this.baseUrl + '/InformationMemorandum';

  constructor(private http: HttpClient, private utilitiesService: UtilitiesService) { }

  registerInformationMemorandum(informationMemorandum: IInformationMemorandum): Observable<IInformationMemorandum> {
    return this.http.post<IInformationMemorandum>(
      this.informationMemorandumBaseUrl + '/InformationMemorandum', informationMemorandum)
      .pipe(map(informationMemorandum => informationMemorandum), catchError(this.handleError));

  }

  addOrUpdateExecutiveSummary(executiveSummary : IExecutiveSummary): Observable<IExecutiveSummary>
  {
    return this.http.put<IExecutiveSummary>(
      this.informationMemorandumBaseUrl + '/ExecutiveSummary', executiveSummary)
      .pipe(map(executiveSummaryId => executiveSummaryId), catchError(this.handleError));
  }

  addOrUpdateProductAndMarket(productAndMarket: IProductAndMarket): Observable<IProductAndMarket> {
    return this.http.put<IProductAndMarket>(
      this.informationMemorandumBaseUrl + '/ProductAndMarket', productAndMarket)
      .pipe(map(productAndMarket => productAndMarket), catchError(this.handleError));

  }

  
  addOrUpdateStaff(staff : IStaff): Observable<IStaff>
  {
    return this.http.put<IStaff>(
      this.informationMemorandumBaseUrl + '/Staff', staff)
      .pipe(map(staf => staf), catchError(this.handleError));
  }


  addOrUpdateAsset(asset : IAsset): Observable<IAsset>
  {
    return this.http.put<IAsset>(
      this.informationMemorandumBaseUrl + '/Asset', asset)
      .pipe(map(asset => asset), catchError(this.handleError));
  }


  addOrUpdateProspect(prospect : IProspect): Observable<IProspect>
  {
    return this.http.put<IProspect>(
      this.informationMemorandumBaseUrl + '/Prospect', prospect)
      .pipe(map(prospect => prospect), catchError(this.handleError));
  }
   

  getInformationMemorandum(companyDetailsId: number): Observable<IInformationMemorandum> {
    return this.http.get<IInformationMemorandum>(
      this.informationMemorandumBaseUrl + '/Businessplan' + '/' + companyDetailsId);
  }

   

  getBusinessPlan(companyDetailsId: number): Observable<IInformationMemorandum> {
    return this.http.get<IInformationMemorandum>(
      this.informationMemorandumBaseUrl + '/Businessplan' + '/' + companyDetailsId);
  }

 

  addOrUpdateFinance(finance : IFinance): Observable<IFinance>
  {
    return this.http.put<IFinance>(
      this.informationMemorandumBaseUrl + '/Finance', finance)
      .pipe(map(prospect => prospect), catchError(this.handleError));
  }


  addOrUpdateInformationMemorandumSummary(informationMemorandumSummary : IInformationMemorandumSummary): Observable<IInformationMemorandumSummary>
  {
    return this.http.put<IInformationMemorandumSummary>(
      this.informationMemorandumBaseUrl + '/InformationMemorandumSummary', informationMemorandumSummary)
      .pipe(map(prospect => prospect), catchError(this.handleError));
  }

  addOrUpdatePreview(preview : IPreview): Observable<IPreview>
  {
    return this.http.put<IPreview>(
      this.informationMemorandumBaseUrl + '/Preview', preview)
      .pipe(map(preview => {
        preview.ProductsArray = preview.Products.split(",");
        preview.SectorsArray = preview.Sectors.split(",");
        return preview;
      }), catchError(this.handleError));
  }

  getFileList(propertyName : string, propertyId : number): Observable<Array<IFileUploadInformationMemorandum>>
  {
    const params = new HttpParams()
   .set('propertyName', propertyName)
   .set('propertyId', propertyId.toString())

   return this.http.get<Array<IFileUploadInformationMemorandum>>( this.informationMemorandumBaseUrl + "/FileList", {params} );

  }

  addFileUploadInformationMemorandums(fileUploadInformationMemorandums : Array<IFileUploadInformationMemorandum>)
  {
    return this.http.put<Array<IFileUploadInformationMemorandum>>(
      this.informationMemorandumBaseUrl + '/FileUploadInformationProperty', fileUploadInformationMemorandums)
      .pipe(map(fileUploadInformationMemorandums => fileUploadInformationMemorandums), catchError(this.handleError));
    
  }

  getGeographyList() : Array<string>
  {
    return ["UK", "EU", "US", "NA", "ME", "APAC", "Global"];
  }


  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'server error');
  }
}
