import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-optimise',
  templateUrl: './optimise.component.html',
  styleUrls: ['./optimise.component.scss']
})
export class OptimiseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
