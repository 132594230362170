import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { mergeMap } from 'rxjs/operators';
import { CompanyDetailsService } from 'src/app/core/services/company-details';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { ICompanyDetails } from 'src/app/shared/ICompanyDetails';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  public fgCompany: FormGroup;
  constructor(private fb: FormBuilder,
    private userStateService: UserStateService,
    private companyDetailsService: CompanyDetailsService,
    private router: Router,
    private spinner: NgxSpinnerService) {

    this.fgCompany = this.fb.group({
      Name: ['', [Validators.required, Validators.minLength(2)]]
    });
  }

  get name() { return this.fgCompany.get('Name'); }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.spinner.show();
    let companyDetail = {} as ICompanyDetails;
    companyDetail.Name = this.fgCompany.get('Name').value;
    companyDetail.ExecutiveDirectorId = this.userStateService.userDetailId;
    
    this.companyDetailsService.addSecondaryCompanyDetails(companyDetail)
      .pipe(mergeMap(_ => this.userStateService.updateSelectedCompany(this.userStateService.userDetailId)))
      .subscribe(_ => {
        this.spinner.hide();
        this.router.navigate(['company/'+this.userStateService.selectedCompanyId+'/settings/selectedcompany'])
       
      }
      );
  }

}
