import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IActionTracker } from 'src/app/shared/CorporateFinanceTool/IActionTracker';

@Component({
  selector: 'app-action-tracker-dialog',
  templateUrl: './action-tracker-dialog.component.html',
  styleUrls: ['./action-tracker-dialog.component.scss']
})
export class ActionTrackerDialogComponent implements OnInit {

  public  actionTrackerFg: FormGroup;

  public PriorityList : Array<string> = ["High", "Medium", "Low", "None"];
  public ProgressList : Array<string> = ["Blank", "Green", "Yellow", "Red"];
  public StatusList : Array<string> = ["Open", "Closed", "Not Started"];


  constructor(public fb: FormBuilder,
    public dialogRef: MatDialogRef<ActionTrackerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public actionTracker: IActionTracker) { 


    
    this.actionTrackerFg =
    this.fb.group({
      ActionTrackerId : [this.actionTracker.ActionTrackerId, []],
      CompanyId: [this.actionTracker.CompanyId, []],
      Action :  ['', []], 
      Area: ['', []], //company Id
      ActionResult : ['', []],
      Owner:  ['', []],
      Priority : ['', []],
      Progress: ['New Lead', []],
      Status: ['', []],
      DueDate :  ['', []],
    });

  }

  ngOnInit(): void {
    this.actionTrackerFg.patchValue(this.actionTracker);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
 
    var actionTraderTmp = this.actionTrackerFg.value as IActionTracker;

    Object.assign(this.actionTracker, actionTraderTmp);

    this.dialogRef.close(this.actionTracker);
  }

}
