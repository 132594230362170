import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, take } from 'rxjs/operators';
import { CorporateFinanceToolService } from 'src/app/core/services/corporate-finance-tool.service';
import { PreRegisterService } from 'src/app/core/services/pre-register.service';
import { ThreeSixtyReportsService } from 'src/app/core/services/three-sixty-reports.service';
import { UserDetailsService } from 'src/app/core/services/user-details.service';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from 'src/app/shared/core-component/confirmation-dialog/confirmation-dialog.component';
import { ICorporateFinanceForm } from 'src/app/shared/CorporateFinanceTool/ICorporateFinanceForm';
import { IThreeSixtyReport } from 'src/app/shared/CorporateFinanceTool/IThreeSixtyReport';
import { ICompanyDetails } from 'src/app/shared/ICompanyDetails';
import { IPreRegistration } from 'src/app/shared/IPreRegistration';
import { environment } from 'src/environments/environment';

import { CorporateFinanceLeadGenService } from '../corporate-finance-lead-gen.service';
import { CorporateFinanceFormDialogComponent } from './corporate-finance-form-dialog/corporate-finance-form-dialog.component';

@Component({
  selector: 'app-lead-gen-listing',
  templateUrl: './lead-gen-listing.component.html',
  styleUrls: ['./lead-gen-listing.component.scss']
})
export class LeadGenListingComponent implements OnInit {

  displayedColumnCorporateFinanceForm: string[] =
    [
      'CreatedDate',
      'CompanyName',
      'Email',
  //    'FirstName',
      'LastName',
      'ProgressState',
      'freeReport',
      'actions'
    ];

  //  'PhoneNumber',
  // 'Sector',
  // 'Revenue',
  // 'Profit',
  // 'EBITDA',


  public corporateFinanceDataSource = new MatTableDataSource<ICorporateFinanceForm>([]);
  private corporateFinanceForms = new Array<ICorporateFinanceForm>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private corporateFinanceLeadGenService: CorporateFinanceLeadGenService,
    private dialog: MatDialog,
    private corporateFinanceToolService: CorporateFinanceToolService,
    private userStateService: UserStateService,
    private preRegisterService: PreRegisterService,
    private threeSixtyReportsService: ThreeSixtyReportsService,

  ) {

    this.activatedRoute.data.subscribe((data: any) => {
      this.corporateFinanceForms = data.corporateFinanceFormResolver as Array<ICorporateFinanceForm>;

    });

  }

  ngOnInit(): void {
    this.corporateFinanceDataSource.data = this.corporateFinanceForms;

  }


  deleteCorporateForm(corporateFinanceFormId: number) {
    this.corporateFinanceToolService.delete(corporateFinanceFormId).subscribe((result: boolean) => {
      if (result) {
        this.corporateFinanceForms = this.corporateFinanceForms.filter(m => m.CorporateFinanceFormId != corporateFinanceFormId);
        this.corporateFinanceDataSource.data = this.corporateFinanceForms;
      }
    }
    );
  }

  addCompanyToPlatform(corporateFinanceFormId: number): void {

    var corporateFinanceForm = this.corporateFinanceForms.find(m => m.CorporateFinanceFormId != corporateFinanceFormId);

    this.corporateFinanceToolService.AssignCompanyToMyPrimparyCompany(corporateFinanceForm).subscribe((updatedCorporateFinanceForm: ICorporateFinanceForm) => {
      corporateFinanceForm = updatedCorporateFinanceForm;
    });

  }


  openDialog(corporateFinanceForm: ICorporateFinanceForm, isAdd: boolean) {

    const dialogRef = this.dialog.open(CorporateFinanceFormDialogComponent, {
      data: corporateFinanceForm,
      position: { top: '30px' },
      width: '60%',
    });

    if (isAdd == true) {
      dialogRef.afterClosed()
        .pipe(take(1), mergeMap((corporateFinanceForm: ICorporateFinanceForm) => this.corporateFinanceToolService.addCorporateFinance(corporateFinanceForm)))
        .subscribe((corporateFinanceFormOutput: ICorporateFinanceForm) => {

          corporateFinanceForm = corporateFinanceFormOutput;

          this.registerNewCompany(corporateFinanceForm);

          this.corporateFinanceForms.unshift(corporateFinanceForm);

          this.corporateFinanceDataSource.data = this.corporateFinanceForms;



        });
    }
    else {
      dialogRef.afterClosed()
        .pipe(take(1), mergeMap((corporateFinanceForm: ICorporateFinanceForm) => this.corporateFinanceToolService.updateCorporateFinance(corporateFinanceForm)))
        .subscribe(_ => {


          //  this.corporateFinanceForms.filter(m => m.CorporateFinanceFormId == corporateFinanceForm.CorporateFinanceFormId)[0] = corporateFinanceForm;

          this.corporateFinanceDataSource.data = this.corporateFinanceForms;
        }
        );


    }


  }


  registerNewCompany(corporateFianceForm: ICorporateFinanceForm) {


    let preRegistration: IPreRegistration = {} as IPreRegistration;

    let companyDetail = {} as ICompanyDetails;
    companyDetail.Name = corporateFianceForm.CompanyName;
    preRegistration.UserTypeId = 3;
    preRegistration.CorporateFinanceFormId = corporateFianceForm.CorporateFinanceFormId;
    preRegistration.RegisteredById = this.userStateService.userDetailId;

    this.preRegisterService.CreatePreRegister(preRegistration).pipe(

      mergeMap((preRegistration: IPreRegistration) => {
        var threeSixtyReport = {
          ThreeSixtyReportId: 0,
          BasicValuationId: corporateFianceForm.CompanyValuationBasicId,
          QuestionnaireId: corporateFianceForm.QuestionnaireFormId,
          UserId: preRegistration.UserDetailsId,
          CompanyId: preRegistration.CompanyDetailsId,
          IsPublished: false,
          MainCommentary: "",
          CorporateFinanceFormId: corporateFianceForm.CorporateFinanceFormId

        } as IThreeSixtyReport;

        preRegistration.ThreeSixtyReport = threeSixtyReport;

        return this.threeSixtyReportsService.RegisterThreeSixtyReport(preRegistration);
      }
      )).subscribe((threeSixtyReport: IThreeSixtyReport) => {

        corporateFianceForm.ThreeSixtyReportId = threeSixtyReport.ThreeSixtyReportId;
        this.userStateService.AddedNewCompany.next(true);

      });

  }


  editCorporateForm(corporateFinanceFormId: number) {

    var selectedCorporateFianceForm = this.corporateFinanceForms.filter(m => m.CorporateFinanceFormId == corporateFinanceFormId)[0];

    this.openDialog(selectedCorporateFianceForm, false);

  }

  addCorporateForm(): void {
    var corporateFianceForm = {} as ICorporateFinanceForm;
    corporateFianceForm.CorporateFinanceFormId = 0;
    corporateFianceForm.ApplicationId = this.userStateService.selectedCompanyId;
    var addedCorporateFinanceForm = this.openDialog(corporateFianceForm, true);

  }



  openCorporateForm(corporateFinanceFormId: number) {

    var selectedCorporateFianceForm = this.corporateFinanceForms.filter(m => m.CorporateFinanceFormId == corporateFinanceFormId)[0];
    this.corporateFinanceLeadGenService.SelectedICorporateFinanceForm = selectedCorporateFianceForm;

    if (selectedCorporateFianceForm.ThreeSixtyReportId != null && selectedCorporateFianceForm.ThreeSixtyReportId != 0) {

      this.router.navigate(["../lead-report/threeSixtyReport/" + selectedCorporateFianceForm.ThreeSixtyReportId], { relativeTo: this.activatedRoute });
    }
    else {

      this.router.navigate(["../lead-report/create"], { relativeTo: this.activatedRoute });
    }

  }


  confirmDialog(): void {

    const message = `Are you sure you want to do this?`;

    const dialogData = new ConfirmationDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      var result = dialogResult;

    });

  }

  deleteCorporateFormDialog(corporateFinanceFormId: number): void {

    const message = `Are you sure you want delete this prospect record?`;

    const dialogData = new ConfirmationDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      var result = dialogResult as boolean;

      if (result) {
        this.deleteCorporateForm(corporateFinanceFormId);
      }

    });

  }

  addCompanyToPlatformDialog(corporateFinanceFormId: number) {

    const message = `Are you sure you want add company to platform?`;

    const dialogData = new ConfirmationDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      var result = dialogResult as boolean;

      if (result) {
        this.addCompanyToPlatform(corporateFinanceFormId);
      }

    });

  }

}
