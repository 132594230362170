import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { UserDetailPanelComponent } from './user-detail-panel/user-detail-panel.component';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { CompanyDetailPanelComponent } from './company-detail-panel/company-detail-panel.component';


@NgModule({
  declarations: [ 
    SidenavComponent,
    UserDetailPanelComponent,
    CompanyDetailPanelComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  exports: [
    SidenavComponent,
    UserDetailPanelComponent,
    CompanyDetailPanelComponent]
})
export class SidenavModule { }
