import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { numbers } from '@material/tab-bar';
import { merge } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';
import { CompanyDetailsService } from 'src/app/core/services/company-details';
import { UserDetailsService } from 'src/app/core/services/user-details.service';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { CompanySettingConfig } from 'src/app/shared/CompanySettingConfig';
import { ICompanyDetails } from 'src/app/shared/ICompanyDetails';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
export class CompanyFormComponent implements OnInit {
  public fgCompany: FormGroup;
  public background: ThemePalette = 'primary';
  private objectId: string;
  links = ['Details'];
  activeLink = this.links[0];

  public Juridictions: Array<string> = CompanySettingConfig.Juridictions;

  public Currencies: Array<{code: string, name:string }> = CompanySettingConfig.Currencies;

  public Types: Array<string> = CompanySettingConfig.Types;

  public companyName : string;
  constructor(
    private fb: FormBuilder,
    private companyDetailsService: CompanyDetailsService,
    private userStateService: UserStateService,
    private snackBar: MatSnackBar,
    private router: Router

  ) {

    this.fgCompany = this.fb.group({
      Name: ['', []],
      Website: ['', []],
      IncorportedDate: ['', []],
      CompanyType: ["LTD - Private company limited by shares", []],
      Currency: ['GBP', []],
      Jurisdiction: ['England and Wales', []],
      CompaniesHouseNumber: ['', []],
      UTR: ['', []],
      HMRC_ReferenceNumber: ['', []],
      VATnumber: ['', []],
      Address: this.fb.group({
        Phone: ['', []],
        Line1: ['', []],
        Line2: ['', []],
        City: ['', []],
        Postcode: ['', []],
      }),
      BankAccount: this.fb.group({
        Name: ['', []],
        AccountNumber: ['', []],
        SortCode: ['', []],
        AccountIBAN: ['', []],
        AccountSWIFT: ['', []],
      }),
      SocialMediaInformation: this.fb.group({
        Description: ['', []],
        TwitterAccount: ['', []],
        LinkedInProfile: ['', []],
        FacebookPage: ['', []],
      }),
    });

  }



  activateTab(tabIndex: number) {
    this.activeLink = this.links[tabIndex];
  }

  onSubmit() {

    
    this.snackBar.open('Changes saved', '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration : 2500
    });
    
    let companyDetail: ICompanyDetails = { ...this.fgCompany.value };
    companyDetail.ExecutiveDirectorId = this.userStateService.userDetailId;

    companyDetail.CompanyDetailsId = this.userStateService.selectedCompanyId;
    this.companyDetailsService.updateCompanyDetails(companyDetail).subscribe(value => console.log(value));


  }

  ngOnInit(): void {
    if (this.userStateService.selectedCompanyId) {

      this.companyDetailsService.getFullCompanyDetails(this.userStateService.selectedCompanyId)
        .subscribe(companyDetails => { 
          this.fgCompany.patchValue(companyDetails);
          this.companyName = companyDetails.Name;
        });

    }

  }

  removeCompany() {

    this.companyDetailsService.deleteCompany(this.userStateService.selectedCompanyId, this.userStateService.userDetailId)
      .pipe(map(result => {
        if (result) {
          return this.userStateService.updateSelectedCompany(this.userStateService.userDetailId);
        }

        return result;
      }))
      .subscribe((result: boolean) => {
        if (result) {

          window.location.reload();

        }
        else {
          this.snackBar.open('Occurs problem with Delete Company, please contact support', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000
          });
        }
      }
      );

  }

}

