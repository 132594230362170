import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UtilitiesService } from './utilities.service';
import { IUserDetails } from 'src/app/shared/IUserDetails';

@Injectable()
export class UserDetailsService {
    baseUrl = this.utilitiesService.getApiUrl();
    userDetailsBaseUrl = this.baseUrl + '/UserDetails';

    constructor(private http: HttpClient, private utilitiesService: UtilitiesService) {  }

    isUserRegistered(objectId :string): Observable<boolean> {
        return this.http.get<boolean>(this.userDetailsBaseUrl+'/IsUserRegistered'+'/'+objectId);
    }

    getUserDetails(objectId :string): Observable<IUserDetails> {
        return this.http.get<IUserDetails>(this.userDetailsBaseUrl+'/UserDetails'+'/'+ objectId);
    }

    addUserDetails(userDetails: IUserDetails): Observable<number> {
        return this.http.post<number>(this.userDetailsBaseUrl +'/RegisterUserDetails', userDetails)
        .pipe(map(res => res), catchError(this.handleError));
    }

    preRegisterUserDetails(userDetails : IUserDetails) : Observable<IUserDetails>
    {
        return this.http.post<IUserDetails>(this.userDetailsBaseUrl +'/RegisterInvitedUser', userDetails)
        .pipe(map(res =>   res as IUserDetails ), catchError(this.handleError));
    }

    
    preRegisterBuyer(userDetails : IUserDetails) : Observable<IUserDetails>
    {
        return this.http.post<IUserDetails>(this.userDetailsBaseUrl +'/RegisterInvitedBuyer', userDetails)
        .pipe(map(res =>   res as IUserDetails ), catchError(this.handleError));
    }


    
    updateUserDetails(userDetails: IUserDetails): Observable<boolean> {
        return this.http.post<IUserDetails>(this.userDetailsBaseUrl +'/UpdateUserDetails', userDetails)
        .pipe(map(res => true), catchError(this.handleError));
    }


    updateUserValuationSubscription(userDetailId: number, valuationSubscription : number): Observable<any> {
        return this.http.get<any>(this.userDetailsBaseUrl +'/updateUserValuationSubscription/'+userDetailId+'/'+valuationSubscription)
        .pipe(map(res => true), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(errMessage);
        }
        return throwError(error || 'server error');
    }
    
}