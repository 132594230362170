import { Component } from '@angular/core';
import { UserDetailsService } from 'src/app/core/services/user-details.service';
import { UserStateService } from 'src/app/core/services/user-state.service';

@Component({
  selector: 'app-selected-company-info',
  templateUrl: './selected-company-info.component.html',
  styleUrls: ['./selected-company-info.component.scss']
})
export class SelectedCompanyInfoComponent {

  public companyName : string;
  
  constructor(private userStateService: UserStateService)
  {
     this.companyName = userStateService.companyName;
     
    this.userStateService.AddedNewCompany.subscribe((added: Boolean) => {  this.companyName = userStateService.companyName; });
  }

}
