<h1 mat-dialog-title class="mat-h1">Corporate Lead Details</h1>
<form [formGroup]="actionTrackerFg" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div fxLayout="column" fxLayoutAlign="center stretch">


            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Action </mat-label>
                <input matInput placeholder="" formControlName="Action" />
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Area</mat-label>
                <input matInput placeholder="" formControlName="Area" />
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>ActionResult</mat-label>
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" matInput
                    placeholder="Provide a brief overview" formControlName="ActionResult"></textarea>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Owner</mat-label>
                <input matInput placeholder="" formControlName="Owner" />
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Priority</mat-label>
                <mat-select required formControlName="Priority">
                    <mat-option *ngFor="let priority of PriorityList" [value]="priority">
                        {{priority}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Process Stage</mat-label>
                <mat-select required formControlName="Progress">
                    <mat-option *ngFor="let progress of ProgressList" [value]="progress">
                        {{progress}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="1 1 auto">
                <mat-label>Status</mat-label>
                <mat-select required formControlName="Status">
                    <mat-option *ngFor="let status of StatusList" [value]="status">
                        {{status}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="8px">

                <span>DueDate:</span>
                <mat-form-field appearance="fill" class="example-form-field">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="DueDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>


            </div>




            <!-- <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Process Stage</mat-label>
                    <mat-select required formControlName="ProcessStage">
                        <mat-option *ngFor="let stage of Stages" [value]="stage">
                            {{stage}}
                          </mat-option>
                    </mat-select>
                </mat-form-field> -->



        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button type="button" (click)="onCancel()" mat-stroked-button color="primary" fxFlex="1 1 auto">Cancel</button>
        <button mat-stroked-button color="primary" fxFlex="1 1 auto" type="submit"
            [disabled]="!actionTrackerFg.valid">Save</button>
    </div>
</form>