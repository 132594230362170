import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IThreeSixtyReport } from 'src/app/shared/CorporateFinanceTool/IThreeSixtyReport';
import { IPreRegistration } from 'src/app/shared/IPreRegistration';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ThreeSixtyReportsService {

  
  baseUrl = this.utilitiesService.getApiUrl();
  threeSixtyBaseUrl = this.baseUrl + '/ThreeSixtyReports';

  constructor(private http: HttpClient, private utilitiesService: UtilitiesService) 
  {
  }


  public getThreeSixtyReportByCompanyId(companyId : number) :  Observable<IThreeSixtyReport>
  {
        return this.http.get<IThreeSixtyReport>(
        this.threeSixtyBaseUrl + '/report'+'/'+companyId );
  }
 
  
  public getThreeSixtyReport(threeSixtyReportId : number) :  Observable<IThreeSixtyReport>
  {
        return this.http.get<IThreeSixtyReport>(
        this.threeSixtyBaseUrl + '/threeSixtyReportId'+'/'+threeSixtyReportId );
  }


 public AddUpdateThreeSixty(threeSixtyReport: IThreeSixtyReport): Observable<IThreeSixtyReport> {
    return this.http.post<IThreeSixtyReport>(
      this.threeSixtyBaseUrl + '/ThreeSixtyReport', threeSixtyReport);
    }

   // RegisterThreeSixtyReport

    public RegisterThreeSixtyReport(preRegistration: IPreRegistration): Observable<IThreeSixtyReport> {
      return this.http.post<IThreeSixtyReport>(
        this.threeSixtyBaseUrl + '/RegisterThreeSixtyReport', preRegistration );
      }
  

}
