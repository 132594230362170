<!-- <mat-toolbar color="primary">
  <mat-toolbar-row>
      <h1>{{name}}</h1>
      <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
<div fxLayout="column" fxLayoutAlign="start center" class="container"   fxLayoutGap="16px">
  <span class="title">Page is Under Construction</span> 
 
  <upload [disabled]="showProgress" (uploadStatus)="uploadStatus($event)" [(fileStorageLink)]="fileStorageLink" [section]='"InformationMemorandum/Product"'></upload>
  
<h2>File List</h2>
<p *ngIf="showProgress"> progress <strong>{{percentage}}%</strong></p>
<label class="error" [hidden]="!showDownloadError">ci sono stati errori durante il download</label>
<label class="error" [hidden]="!showUploadError">ci sono stati errori durante l'upload</label>
  
  </div> -->

  test test
