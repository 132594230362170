<h1 mat-dialog-title class="mat-h1">Corporate Lead Details</h1>
<form [formGroup]="corporateFianceFg" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div fxLayout="column"  fxLayoutAlign="center stretch">
            
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Company Name</mat-label>
                    <input matInput placeholder="" formControlName="CompanyName" />
                </mat-form-field>
                
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="" formControlName="Email" />
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="" formControlName="FirstName" />
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="" formControlName="LastName" />
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="" formControlName="PhoneNumber" />
                </mat-form-field>

                
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>MADP Contact Owner</mat-label>
                    <input matInput placeholder="" formControlName="MADPContactOwner" />
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Partner Owner</mat-label>
                    <input matInput placeholder="" formControlName="PartnerOwner" />
                </mat-form-field>

                
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Sector</mat-label>
                    <input matInput placeholder="" formControlName="Sector" />
                </mat-form-field>


                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Revenue</mat-label>
                    <input matInput placeholder="" formControlName="Revenue" />
                </mat-form-field>

                
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Profit</mat-label>
                    <input matInput placeholder="" formControlName="Profit" />
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>EBITDA</mat-label>
                    <input matInput placeholder="" formControlName="EBITDA" />
                </mat-form-field>

                
                <!-- <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Lifecycle Stage</mat-label>
                    <mat-select required formControlName="LifecycleStage">
                        <mat-option *ngFor="let lifecycleStage of LifecycleStageList" [value]="lifecycleStage">
                            {{ lifecycleStage }}
                          </mat-option>
                    </mat-select>
                </mat-form-field> -->

<!--                 
                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>LeadSource</mat-label>
                    <input matInput placeholder="" formControlName="LeadSource" />
                </mat-form-field> -->

                <mat-form-field appearance="fill" fxFlex="1 1 auto">
                    <mat-label>Process Stage</mat-label>
                    <mat-select required formControlName="ProgressState">
                        <mat-option *ngFor="let stage of Stages" [value]="stage">
                            {{stage}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>
 
                <mat-form-field appearance="fill"  fxFlex="1 1 auto">
                    <mat-label>Notes</mat-label>
                    <textarea  cdkTextareaAutosize cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="10" matInput placeholder="Provide a brief overview" formControlName="Notes"></textarea>
                </mat-form-field>
           
     
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button type="button" (click)="onCancel()" mat-stroked-button color="primary" fxFlex="1 1 auto">Cancel</button>
        <button mat-stroked-button color="primary" fxFlex="1 1 auto" type="submit"
            [disabled]="!corporateFianceFg.valid">Save</button>
    </div>
</form>