import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { UtilitiesService } from './utilities.service';
import { IFileStorageLink } from 'src/app/shared/IFileStorageLink';

@Injectable()
export class UploadDownloadService {
  private baseApiUrl: string;
  private apiDownloadUrl: string;
  private apiUploadUrl: string;
  private apiFileUrl: string;
  private apiDelete: string;
  private apiGeneralUploadUrl : string;

  private apiGeneralUploadDataroomUrl : string;

  baseUrl = this.utilitiesService.getApiUrl();

  constructor(private httpClient: HttpClient, private utilitiesService: UtilitiesService) {
    this.baseApiUrl = this.baseUrl + '/Documents/';
    this.apiDownloadUrl = this.baseApiUrl + 'DowloadFile';
    this.apiUploadUrl = this.baseApiUrl + 'upload';
    this.apiFileUrl = this.baseApiUrl + 'files';
    this.apiDelete = this.baseApiUrl + 'DeleteFile';
    this.apiGeneralUploadUrl = this.baseApiUrl + 'uploadGeneral';
    this.apiGeneralUploadDataroomUrl = this.baseUrl + '/Dataroom'
    
  }

  // public downloadFile(file: string): Observable<HttpEvent<Blob>> {
  //   return this.httpClient.request(new HttpRequest(
  //     'GET',
  //     `${this.apiDownloadUrl}?file=${file}`,
  //     null,
  //     {
  //       reportProgress: true,
  //       responseType: 'blob'
  //     }));
  // }

  public uploadFile(file: Blob, section: string, companyId : number, fileName : string): Observable<HttpEvent<IFileStorageLink>> {
    const File = new FormData();
    File.append('file', file);
    File.append('Section', section);
    File.append('CompanyId', companyId.toString());
    File.append('FileName', fileName);


    return this.httpClient.request(new HttpRequest(
      'POST',
      this.apiUploadUrl,
      File,
      {
        reportProgress: true
      }));

    //return  this.httpClient.post<HttpEvent<void>>(this.apiUploadUrl, File, { reportProgress : true });

  }


  public uploadFileGeneral(file: Blob, containerName : string, section: string, companyId : number, fileName : string): Observable<HttpEvent<IFileStorageLink>> {
    const File = new FormData();
    File.append('file', file);
    File.append('ContainerName', containerName);
    File.append('Section', section);
    File.append('CompanyId', companyId.toString());
    File.append('FileName', fileName);


    return this.httpClient.request(new HttpRequest(
      'POST',
      this.apiGeneralUploadUrl,
      File,
      {
        reportProgress: true
      }));

    //return  this.httpClient.post<HttpEvent<void>>(this.apiUploadUrl, File, { reportProgress : true });

  }

  
  public uploadFileGeneralDataroom(file: Blob, containerName : string, section: string, companyId : number, fileName : string): Observable<HttpEvent<boolean>> {
    const File = new FormData();
    File.append('file', file);
    File.append('ContainerName', containerName);
    File.append('Section', section);
    File.append('CompanyId', companyId.toString());
    File.append('FileName', fileName);

    const url = `${this.apiGeneralUploadDataroomUrl}/uploadGeneral`;
  
     
    return this.httpClient.request(new HttpRequest(
      'POST',
         url,
      File,
      {
        reportProgress: true
      }));
  }


  public downloadFile(fileStorageLinkId: number): Observable<HttpEvent<Blob>> {
    return this.httpClient.request(new HttpRequest(
      'GET',
      this.apiDownloadUrl+"/"+fileStorageLinkId,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  public deleteFile(fileStorageLinkId: number) : Observable<any>
  {
    return  this.httpClient.get<any>(this.apiDelete+"/"+ fileStorageLinkId);
  }
}
