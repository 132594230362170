<div class="panel">
    <mat-card>
      <h1>How to Create a Valuation</h1>
      <!-- <swiper
        [slidesPerView]="1"
        [spaceBetween]="10"
        [navigation]="true"
        [pagination]="{ clickable: true }"
        [thumbs]="{ swiper: thumbsSwiper }"
        [virtual]="true"
      >
        <ng-template swiperSlide>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div style="margin-left: auto; margin-right: auto">
              <h2>1. Appointing a Corporate Finance Advisor​</h2>
              <iframe
               class="sizeYoutubeFrame"
                src="https://www.youtube.com/embed/PHbbaWdaDyU?start=3"
                title="Appointing a Corporate Finance Advisor"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
  
        <ng-template swiperSlide>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div style="margin-left: auto; margin-right: auto">
              <h2>2. Valuing a business​</h2>
              <iframe
              class="sizeYoutubeFrame"
                src="https://www.youtube.com/embed/dahjth5b_28?start=1"
                title=" Valuing a business"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
  
        <ng-template swiperSlide>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div style="margin-left: auto; margin-right: auto">
              <h2>3. Researching Potential Purchaser Appetite</h2>
              <iframe
              class="sizeYoutubeFrame"
                src="https://www.youtube.com/embed/USt_xHI-m4Q?start=4"
                title="Researching Potential Purchaser Appetite"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
  
        <ng-template swiperSlide>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div style="margin-left: auto; margin-right: auto">
              <h2>4. Writing an Information Memorandum</h2>
              <iframe
              class="sizeYoutubeFrame"
                src="https://www.youtube.com/embed/7UopVA6LjW8?start=2"
                title="Writing an Information Memorandum"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
  
        <ng-template swiperSlide>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div style="margin-left: auto; margin-right: auto">
              <h2>5. Management Buyout Interview​​</h2>
              <iframe
              class="sizeYoutubeFrame"
                src="https://www.youtube.com/embed/Gi4ns4N45Rw?start=2"
                title=" Management Buyout Interview"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </ng-template>
      </swiper> -->

    <vg-player>

      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>
  
      <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>
  
      <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-playback-button></vg-playback-button>
  
          <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
  
          <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
  
          <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
          <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
  
          <vg-track-selector></vg-track-selector>
          <vg-mute></vg-mute>
          <vg-volume></vg-volume>
  
          <vg-fullscreen></vg-fullscreen>
      </vg-controls>
  
      <video [vgMedia]="media" #media id="singleVideo" preload="auto" crossorigin>
          <source src="https://cfvideostore.blob.core.windows.net/cftraining/Corporate_Finance_Training_Company_Valuation_Application.mp4" type="video/mp4">
      </video>
  </vg-player>

 

    </mat-card>
  </div>
  