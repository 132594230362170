import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UtilitiesService } from './utilities.service';
import { ICompanyDetails } from 'src/app/shared/ICompanyDetails';
import { ISwitchCompanySetting } from 'src/app/shared/ISwitchCompanySetting';

@Injectable()
export class CompanyDetailsService {
    baseUrl = this.utilitiesService.getApiUrl();
    companyDetailsBaseUrl = this.baseUrl + '/CompanyDetails';

    constructor(
        private http: HttpClient,
        private utilitiesService: UtilitiesService,
    ) { }

    addCompanyDetails(companyDetails: ICompanyDetails): Observable<number> {
        return this.http.post<number>(
            this.companyDetailsBaseUrl + '/AddCompanyDetails' , companyDetails)
            .pipe(map((companyId : number ) => companyId), catchError(this.handleError));
    }

    
    addSecondaryCompanyDetails(companyDetails: ICompanyDetails): Observable<number> {
        return this.http.post<number>(
            this.companyDetailsBaseUrl + '/AddSecondaryCompanyDetails' , companyDetails)
            .pipe(map((companyId : number ) => companyId), catchError(this.handleError));
    }

    updateCompanyDetails(companyDetails: ICompanyDetails): Observable<boolean> {
        return this.http.post<ICompanyDetails>(
            this.companyDetailsBaseUrl + '/UpdateCompanyDetails' , companyDetails)
            .pipe(map(res => true), catchError(this.handleError));
    }

    getFullCompanyDetails(companyId: number): Observable<ICompanyDetails> {
        return this.http.get<ICompanyDetails>(
            this.companyDetailsBaseUrl + '/CompanyDetails' + '/' + companyId
        );
    }

    
    deleteCompany(companyId: number, userId : number): Observable<boolean> {
        return this.http.delete<boolean>(
            this.companyDetailsBaseUrl + '/DeleteCompany' + '/' + companyId + '/' + userId
        );
    }


    getCompaniesByExecutiveDirectorId(
        objectId: string
    ): Observable<ICompanyDetails[]> {
        return this.http.get<ICompanyDetails[]>(
            this.companyDetailsBaseUrl +
            '/CampaniesByExecutiveDirectorId' +
            '/' +
            objectId
        );
    }

    getCompaniesListByUserId(
        userId: number
    ): Observable<ICompanyDetails[]> {
        return this.http.get<ICompanyDetails[]>(
            this.companyDetailsBaseUrl +
            '/CompaniesList' +
            '/' +
            userId
        );
    }

    getSelectedCompanyByUserId(userId: number): Observable<ICompanyDetails> {
        return this.http.get<ICompanyDetails>(
            this.companyDetailsBaseUrl +
            '/SelectedCompany' +
            '/' +
            userId
        );
    }

    AddToCompaniesList(userId: number, companyId : number): Observable<boolean> {
        return this.http.get<boolean>(
            this.companyDetailsBaseUrl +
            '/AddToCompaniesList' +
            '/' +
            userId
            +'/'+
            companyId
        );
    }


    
    updateSwitchCompany(switchCompanySetting: ISwitchCompanySetting): Observable<boolean> {
        return this.http.post<ISwitchCompanySetting>(
            this.companyDetailsBaseUrl + '/SwitchActiveCompany' , switchCompanySetting)
            .pipe(map(res => true), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(errMessage);
        }
        return throwError(error || 'server error');
    }
}
