<section class="mat-typography main-layout">
  <mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav mode="side" opened="true">

      <div fxlayout="column" fxlayoutalign="start stretch">
        <div class="scrollable" fxFlex>
          <mat-selection-list [multiple]="false">
              <mat-list-option [routerLink]="['/company/0/pathway/company-form']">
                <div fxlayout="row" fxlayoutalign="space-between center" fxlayoutgap="16px">
                  <mat-icon aria-hidden="false" aria-label="My Tasks" class="p-8">task</mat-icon>
                  <div class="company-name" fxFlex bis_skin_checked="1">
                    Add company
                  </div>
                </div>
              </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
      <user-detail-panel></user-detail-panel>
    </mat-sidenav>
    <mat-sidenav-content class="mat-drawer-content">
     <router-outlet></router-outlet> 
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>