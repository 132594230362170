<mat-accordion>
  <mat-expansion-panel class="company-select">
    <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="80px" >
      <!-- <mat-panel-title> -->
      <div fxlayout="row" fxlayoutalign="start center"  >
        <div class="companyAvatar"  >
          {{ primaryCompanyshortName }}
        </div>
        <div fxFlex>
          <div class="company-name text-truncate">
            {{ primaryCompanyName }} 
          </div>
          <div class="company-roles text-truncate">
            Active: <b>{{ this.selectedCompanyName}} </b>
          </div>
        </div>
      </div>
      <!-- </mat-panel-title> -->
      <!-- <mat-panel-description>
          Type your name and age
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description> -->
    </mat-expansion-panel-header>
    <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" #companiesList>
      <mat-list-option *ngFor="let company of companiesDetails; index as i" (click)="switchCompany(company)"
        [class.selected]="company.IsActive" (selected)="company.IsActive">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <div [ngClass]="company.IsActive ? 'selectedCompanyAvatar' : 'companyAvatar-list'">
              {{ company.Name.substring(0, 1).toUpperCase() }} 
          </div>
          <div class="company-name text-truncate" style="display: inline-block;" fxFlex>
               {{  company.Name }} 
          </div>
        </div>
      </mat-list-option>

      <mat-list-option [routerLink]="['/company-onboarding/new-company']" (selected)='false'>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
            <mat-icon aria-hidden="false" aria-label="add new company">add</mat-icon>

            <div class="company-name" fxFlex bis_skin_checked="1">
              Add New Company
            </div>
          </div>
        </div>
      </mat-list-option>

      <mat-list-option [routerLink]="['/company/'+ companyId +'/settings/company-form']" (selected)='false'>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
            <mat-icon aria-hidden="false" aria-label="add new company">remove</mat-icon>

            <div class="company-name" fxFlex bis_skin_checked="1">
              Delete Company
            </div>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>
</mat-accordion>