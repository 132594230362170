import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { ActionTrackerService } from '../core/services/action-tracker.service';
import { UserStateService } from '../core/services/user-state.service';
import { IActionTracker } from '../shared/CorporateFinanceTool/IActionTracker';

@Injectable({
  providedIn: 'root'
})
export class ActionTrackerResolver implements Resolve<Array<IActionTracker>> {

  constructor(
    private userStateService: UserStateService,
    private spinner: NgxSpinnerService,
    private actionTrackerService : ActionTrackerService
    )
    {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<IActionTracker>> {
   return  this.actionTrackerService.getActionTracker(this.userStateService.selectedCompanyId);
  }

}
