<section class="mat-typography main-layout">stretch
  <mat-sidenav-container fullscreen autosize>
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
      class="mat-sidenav-left">

      <div fxLayout="column">


        <!-- <div class="scrollable" fxFlex> -->
        <company-detail-panel *ngIf="companyId" (updatedCompanyId)="setCompanyId($event)"
          style="max-width: 280px;"></company-detail-panel>
        <mat-nav-list>
          <mat-divider></mat-divider>

          <mat-list-item [routerLink]="['/securechatgpt/']">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Secure GhatGPT">private_connectivity</mat-icon>

              <div class="option-text" fxFlex>
                Secure ChatGPT
              </div>
            </div>
          </mat-list-item>
           
          <mat-list-item [routerLink]="['/ai-copilot/']">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Secure GhatGPT">psychology</mat-icon>

              <div class="option-text" fxFlex>
                Advisory Copilot
              </div>
            </div>
          </mat-list-item>

          <mat-list-item>
            <a href="https://www.madealplatform.com/ma-deal-platform-help" target="_blank">
              <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">

                <mat-icon aria-hidden="false" aria-label="Help">help_outline</mat-icon>

                <div class="option-text" fxFlex>
                  Help
                </div>

              </div>
            </a>



          </mat-list-item>

          <mat-divider></mat-divider>

          <mat-list-item [routerLink]="['/company/'+ companyId +'/valuation']"
            *ngIf="isDisplay(SideNavs.CompanyValuation)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Finance">account_balance</mat-icon>

              <div class="option-text" fxFlex>
                Company Valuation
              </div>
            </div>
          </mat-list-item>

          <mat-list-item [routerLink]="['/company/'+ companyId +'/business-plan']"
            *ngIf="isDisplay(SideNavs.InformationMemorandum)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Details view">view_compact</mat-icon>

              <div class="option-text" fxFlex>
                Business Plan
              </div>
            </div>
          </mat-list-item>

          <mat-list-item [routerLink]="['/company/'+ companyId +'/information-memorandum']"
            *ngIf="isDisplay(SideNavs.InformationMemorandum)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Details view">view_compact</mat-icon>

              <div class="option-text" fxFlex>
                Information Memorandum
              </div>
            </div>
          </mat-list-item>
          <mat-list-item [routerLink]="['/dataroom/'+ companyId ]" *ngIf="isDisplay(SideNavs.DataRoom)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Details view">storage</mat-icon>

              <div class="option-text" fxFlex>
                Data Room
              </div>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>


          <mat-list-item [routerLink]="['/marketplace']" *ngIf="isDisplay(SideNavs.Marketplace)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Buyers Directory">storefront</mat-icon>
              <div class="option-text" fxFlex>
                Marketplace
              </div>
            </div>
          </mat-list-item>
          <mat-list-item [routerLink]="['/listcompany']" *ngIf="isDisplay(SideNavs.Listcompany)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="List Directory">list_alt</mat-icon>
              <div class="option-text" fxFlex>
                List Company
              </div>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>









          <mat-list-item [routerLink]="['/corporate-lead-gen-list']" *ngIf="isDisplay(SideNavs.Dashboard)">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Buyers Directory">space_dashboard</mat-icon>
              <div class="option-text" fxFlex>
                Prospects
              </div>
            </div>
          </mat-list-item>

          <mat-list-item [routerLink]="['/company/'+ companyId +'/settings/company-form']">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <mat-icon aria-hidden="false" aria-label="Company Setting">settings</mat-icon>

              <div class="option-text" fxFlex>
                Company Setting
              </div>
            </div>
          </mat-list-item>



          <mat-list-item (click)="drawer.toggle()">
            <div fxlayout="row" fxlayoutalign="space-between center" fxLayoutGap="3px">
              <!-- <mat-icon aria-hidden="false" aria-label="Company Setting" >view_sidebar</mat-icon> -->

              <div class="option-text" fxFlex>
                .
              </div>
            </div>
          </mat-list-item>
        </mat-nav-list>
        <!---->
        <!---->
      </div>
      <mat-divider style="margin-bottom: 8px;"></mat-divider>


      <user-detail-panel></user-detail-panel>


    </mat-sidenav>
    <mat-sidenav #rightPanel class="panel" position="end" style="width: 400px;">

      <!-- <pre><code>{{ (panelService.panelPortal$ | async) | json }}</code></pre> -->
      <ng-template [cdkPortalOutlet]="panelService.panelPortal | async"></ng-template>
    </mat-sidenav>
    <mat-sidenav-content class="mat-drawer-content">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="!drawer.opened || (isHandset$ | async)">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>