import { Component,ViewChild, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { json } from '@rxweb/reactive-form-validators';
import { jsonValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import { CorporateFinanceLeadGenService } from '../corporate-finance-lead-gen/corporate-finance-lead-gen.service';
import { IFileStorageLink } from '../shared/IFileStorageLink';
import { ProgressStatus, ProgressStatusEnum } from '../shared/progress-status/progress-status.model';
//import { SignaturePad } from 'angular2-signaturepad';

//import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  public files: string[];
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showUploadError: boolean;
  public fileStorageLink : IFileStorageLink;

  //@ViewChild('uploadForm') private uploadForm = null;


  ngOnInit(): void {
    // const dialogUserTypeRef = this.dialog.open(DialogSignaturePad, { disableClose: true });
    // this.router.url.subscribe((url: UrlSegment[])=> this.name =url[2].path);

    // console.log(this.router.parent.snapshot.url);
  }

  public uploadStatus(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showUploadError = false;
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.percentage = event.percentage;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
       // this.getFiles();
       console.log(this.fileStorageLink);
      // this.fileStorageLink = uploadForm
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showUploadError = true;
        break;
    }
  }

  
  // @ViewChild(SignaturePad) signaturePad: SignaturePad;

  // public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
  //   'minWidth': 5,
  //   'canvasWidth': 500,
  //   'canvasHeight': 300
  // };
   name : string;

  constructor(private router: ActivatedRoute, private corporateFinanceLeadGenService: CorporateFinanceLeadGenService ) {
    console.log(JSON.stringify(corporateFinanceLeadGenService.SelectedICorporateFinanceForm));
  }

  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
  //   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  // }

  // drawComplete() {
  //   // will be notified of szimek/signature_pad's onEnd event
  //   console.log(this.signaturePad.toDataURL());
  // }

  // drawStart() {
  //   // will be notified of szimek/signature_pad's onBegin event
  //   console.log('begin drawing');
  // }

}


// @Component({
//   selector: 'dialog-signature-pad',
//   templateUrl: 'dialog-signature-pad.html',
//   styleUrls: ['./test.component.scss']
// })
// export class DialogSignaturePad implements OnInit {

//   constructor(private router: Router, public dialogRef: MatDialogRef<DialogSignaturePad>) { }

//   ngOnInit() {

//   }

//   setUserType(userType: string): void {
//     this.dialogRef.close();
//   }


//   @ViewChild(SignaturePad) signaturePad: SignaturePad;

//   public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
//     'minWidth': 5,
//     'canvasWidth': 500,
//     'canvasHeight': 300
//   };


//   ngAfterViewInit() {
//     // this.signaturePad is now available
//     this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
//     this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
//   }

//   drawComplete() {
//     // will be notified of szimek/signature_pad's onEnd event
//     console.log(this.signaturePad.toDataURL());
//   }

//   drawStart() {
//     // will be notified of szimek/signature_pad's onBegin event
//     console.log('begin drawing');
//   }
// }
