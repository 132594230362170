<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedheight="80px" expandedheight="80px">
      <mat-panel-title>
        <div fxlayout="row" fxlayoutalign="start center" fxlayoutgap="16px">
          <div fxFlex="1 1 auto">
            <div class="userAvatar" >
              {{shortName}}
            </div>
          </div>
          <div></div>
          <div user-display fxFlex="1 1 auto">
            <div class="user-display">{{fullNameUser}}</div>
            <div class="user-email">{{email}}</div>
          </div>

        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-selection-list [multiple]="false"  [hideSingleSelectionIndicator]="true" >
      <mat-list-option [routerLink]="['/user/user-form']">

        <div fxlayout="row" fxlayoutalign="space-between center" >
          <mat-icon aria-hidden="false" aria-label="use details">manage_accounts</mat-icon>

          <div class="company-name" fxFlex bis_skin_checked="1">
            Details
          </div>
        </div>
      </mat-list-option>
      <mat-list-option (click)="logout()">

        <div fxlayout="row" fxlayoutalign="space-between center" >
          <mat-icon aria-hidden="false" aria-label="logout" >logout</mat-icon>

          <div class="company-name" fxFlex bis_skin_checked="1">
            Logout
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>
</mat-accordion>