import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grow',
  templateUrl: './grow.component.html',
  styleUrls: ['./grow.component.scss']
})
export class GrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
