import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserStateService } from 'src/app/core/services/user-state.service';

@Component({
  selector: 'user-detail-panel',
  templateUrl: './user-detail-panel.component.html',
  styleUrls: ['./user-detail-panel.component.scss']
})
export class UserDetailPanelComponent implements OnInit {

  fullNameUser : string;
  email: string;
  shortName :string;
  constructor(private authService: MsalService, private userState : UserStateService)
  {
    this.shortName = this.userState.userName.substring(0,1).toUpperCase()+'.'+ this.userState.userSurname.substr(0,1).toUpperCase()+'.';
    this.fullNameUser =  this.userState.userName +' '+this.userState.userSurname;
    this.email = this.userState.userEmail;
  }

  ngOnInit(): void {
   

  }
  
  logout(): void
  {
    this.authService.logout();
  }

}
