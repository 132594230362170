<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>Company Settings</span>
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="page-container">
  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end start"> 

       <button type="button" mat-raised-button color="warn" (click)="removeCompany()">Delete Company : {{companyName}} </button>  
  </div>
  <mat-divider style="margin-top: 8px; margin-bottom: 8px;"></mat-divider>
  <form [formGroup]="fgCompany" (ngSubmit)="onSubmit()">
    <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Company Name</mat-label>
          <input matInput placeholder="" formControlName="Name"   require/>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill" fxFlex.gt-xs="33">
          <mat-label>Company Formation Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="IncorportedDate"  />
          <mat-datepicker-toggle matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="14px">
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Company Website</mat-label>
          <input matInput placeholder="" formControlName="Website" />
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Type</mat-label>
          <mat-select   formControlName="CompanyType">
            <mat-option *ngFor="let type of Types" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="14px">
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Default currency</mat-label>
          <mat-select  formControlName="Currency">
            <mat-option *ngFor="let currency of Currencies" [value]="currency.code">
              {{currency.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Jurisdiction</mat-label>
          <mat-select  formControlName="Jurisdiction">
          <mat-option *ngFor="let juridiction of Juridictions" [value]="juridiction">
            {{juridiction}}
          </mat-option>
        </mat-select> 
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Companies House number</mat-label>
          <input matInput placeholder="" formControlName="CompaniesHouseNumber" />
          <mat-hint>Your Companies House registration numbe</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>UTR</mat-label>
          <input matInput placeholder="" formControlName="UTR" />
          <mat-hint>Your company Unique Taxpayer Reference. Must be 10 digits.You'll
            need a UTR before you can apply for SEIS/EIS Advance Assurance.
            Leave blank if you don't have one yet.</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>HMRC Reference Number</mat-label>
          <input matInput placeholder="" formControlName="HMRC_ReferenceNumber" />
          <mat-hint>Your HMRC reference, if you have one. If not, leave blank. Enter
            the 10 digits before /SCEC e.g. 5874678971</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>VAT Number</mat-label>
          <input matInput placeholder="" formControlName="VATnumber" />
          <mat-hint>Your company VAT number, if you have one, starting with the
            country code, e.g. GB123456789</mat-hint>
        </mat-form-field>
      </div>
      <mat-divider class="extraSeparator"></mat-divider>
      <div fxLayout="column" fxLayoutGap="14px" fxLayoutAlign="center stretch">
        <div formGroupName="Address">
          <h2>Company Registered Address </h2>
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <mat-form-field appearance="fill">
              <mat-label>Line1</mat-label>
              <input matInput placeholder="" formControlName="Line1" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Line2</mat-label>
              <input matInput placeholder="" formControlName="Line2" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>City</mat-label>
              <input matInput placeholder="" formControlName="City" />
              <mat-hint>City</mat-hint>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
            <mat-form-field appearance="fill" fxFlex="1 1 auto">
              <mat-label>Postcode</mat-label>
              <input matInput placeholder="" formControlName="Postcode" />
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="1 1 auto">
              <mat-label>Country</mat-label>
              <select matNativeControl>
                <option value="UK">UK</option>
              </select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <h2>Company Bank Account</h2>
      <p>
        We'll insert these account details into your agreements so you don't
        need to fill them again.
      </p>
      <div formGroupName="BankAccount">
        <div fxlayout.xs="column" fxlayout.gt-xs="row" fxLayoutAlign="center stretch" fxlayoutgap.gt-xs="16px">
          <mat-form-field appearance="fill" fxFlex>
            <mat-label>Company Account Name</mat-label>
            <input matInput placeholder="" formControlName="Name" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxlayout.xs="column" fxLayoutAlign="space-around center" fxlayout.gt-xs="row wrap">
          <mat-form-field appearance="fill" fxFlex="1 1 auto">
            <mat-label>Account Number</mat-label>
            <input matInput placeholder="" formControlName="AccountNumber" />
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="1 1 auto">
            <mat-label>Sort Code</mat-label>
            <input matInput placeholder="" formControlName="SortCode" />
          </mat-form-field>
        </div>
      </div>

      <mat-divider></mat-divider>
      <h2>Social</h2>
      <div formGroupName="SocialMediaInformation">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-form-field appearance="fill" flex>
            <mat-label>Description</mat-label>
            <input matInput placeholder="" formControlName="Description" />
            <mat-hint>This is just for your company profile page, it's not included
              in any of your documents.</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="fill" flex>
            <mat-label>Twitter Account</mat-label>
            <input matInput placeholder="" formControlName="TwitterAccount" />
            <mat-hint>Your LinkedIn profile URL</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="fill" flex>
            <mat-label>LinkedIn Profile</mat-label>
            <input matInput placeholder="" formControlName="LinkedInProfile" />
            <mat-hint>Your LinkedIn profile URL</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="fill" flex>
            <mat-label>Facebook Page</mat-label>
            <input matInput placeholder="" formControlName="FacebookPage" />
            <mat-hint>Your Facebook profile URL</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div flexLayout="row" fxLayoutAlign="center center">
        <button type="submit" mat-raised-button color="primary" [disabled]="!fgCompany.valid">Save changes</button>
      </div>
    </div>
  </form>
</div>