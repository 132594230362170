
<div fxLayout="row" fxLayoutAlign="start stretch" >
</div>
<mat-card>
<div fxLayout="column" fxLayoutAlign="start end" >
    <button type="button" (click)="sidenavService.panel.close()" mat-icon-button
        aria-label="Example icon button with a heart icon">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-card-header>
    <mat-card-title>Need help?  Or want to talk?​</mat-card-title>
</mat-card-header>
<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
    <a href="tel:+448006404231"  mat-stroked-button>Call + 44 0800 640 4231</a>

<a href="https://www.madealplatform.com/meetings/id/sales"
target="_blank" mat-stroked-button>Schedule a Call</a>
</div>
<mat-card-content style="margin-top: 10px;">
    
    <div>
        <div class="container text">
            As a first step we recommend a confidential, no obligation virtual meeting with one of our M&A advisors, where we discuss your objectives, demo our digital M&A platform and gain an insight into your business.
        </div>
    </div>
</mat-card-content>
<mat-card-actions>
  
</mat-card-actions>
</mat-card>
