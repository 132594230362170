import { Injectable } from '@angular/core';
import { ICorporateFinanceForm } from '../shared/CorporateFinanceTool/ICorporateFinanceForm';

@Injectable({
  providedIn: 'root'
})
export class CorporateFinanceLeadGenService {

  constructor() { }

  public SelectedICorporateFinanceForm : ICorporateFinanceForm;
}
