<mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>Dashboard: {{this.CompanyName}} </span>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <nav mat-tab-nav-bar [backgroundColor]="'primary'"  [tabPanel]="tabPanel">
        <a mat-tab-link  *ngFor="let link of links; index as i;" (click)="activateTab(i)" [active]="activeLink == link[0]"
          [routerLink]="['./'+ link[0] +'']">{{link[1]}} </a>
      </nav>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="page-container">
    <mat-tab-nav-panel #tabPanel> 
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
  </div>