<mat-toolbar color="primary">
    <mat-toolbar-row>
        <h1>Company Detail</h1>
        <span class="example-spacer"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div fxLayout="column" fxlayoutalign="start stretch" class="container" fxLayoutGap="16px">
    <span class="title">Company Detail</span>
    <span class="text">
        Here you enter further detail on your company, including product details, sales pipelines, competitors etc. that is used to enable the platform and M&A Account Director to provide further advise on your company strategy and support the due diligence process. 
    </span>
    <div gdColumns.lt-md="100%" gdColumns="50% 50%" gdgridalign="center stretch">
        <ul>
            <li>
                Upload Contracts, Leases, Accounts and Banks Statements.
            </li>
            <li>
                Verify Key Customers, Suppliers, and Employees.
            </li>
             
        </ul>
        <div>
            <img src="../../../assets/LandinPages/CompanyDetail.png" />
        </div>
    </div>
    <div fxLayout.lt-md="column" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <button class="start-button" mat-raised-button color="primary">Schedule a Meeting </button>
        <button class="start-button" mat-raised-button color="primary" disabled>Get Started</button>
      </div>
</div>