import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged, take } from 'rxjs';
import { CompanyValuationAIService } from 'src/app/core/services/company-valuation-ai.service';
import { IContentText } from 'src/app/shared/TextContent/IContentText';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-content-generator',
  templateUrl: './content-generator.component.html',
  styleUrls: ['./content-generator.component.scss']
})
export class ContentGeneratorComponent implements OnInit {

  public contentGeneratorFg: FormGroup;
  @ViewChild('autosize2') autosize2: CdkTextareaAutosize;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('autosize3') autosize3: CdkTextareaAutosize;

  public ShowLoader : boolean;

  constructor(public fb: FormBuilder,
    private companyValuationAIService: CompanyValuationAIService,
    private spinner: NgxSpinnerService,
    private _ngZone: NgZone,
    private clipboard: Clipboard) {

    this.contentGeneratorFg = this.fb.group({
      ContentTextId: [],
      SubjectContent: [],
      SubjectDetails: [],
      Length: ['less than 500 words'],
      MediumType: ['Article Write'],
      MessageTone: ['Analytical Tone'],
      GeneratedText: [],
      QueryText: [],
      AIEngineType : ['Complex']
    });

  }

  copyContentToClipBoard(): void
  {
    var genText = this.contentGeneratorFg.get("GeneratedText").value as string;
  
    this.clipboard.copy(genText);
  }

  onSubmit(): void {


  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize3.resizeToFitContent(true));
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize2.resizeToFitContent(true));
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
     
    
     
  }

  generateContent() {

    this.ShowLoader = true;
    var contentText = this.contentGeneratorFg.value as IContentText;

    contentText.ContentTextId = 0;
    this.companyValuationAIService.getGeneratedContentByQueryText(contentText).subscribe((result: string) => {
      this.contentGeneratorFg.get("GeneratedText").setValue(result);
      this.ShowLoader = false;

    },
    err =>{
      this.ShowLoader = false;
    });
  }

  ngOnInit(): void {

    this.setQueryBuilder();

    this.contentGeneratorFg.get('SubjectContent').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(x => this.setQueryBuilder());
      
    this.contentGeneratorFg.get('SubjectDetails').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(x => this.setQueryBuilder());
    this.contentGeneratorFg.get('Length').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(x => this.setQueryBuilder());
    this.contentGeneratorFg.get('MediumType').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(x => this.setQueryBuilder());
    this.contentGeneratorFg.get('MessageTone').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(x => this.setQueryBuilder());


 
  }

  setQueryBuilder(): void
  {
    var contentText = this.contentGeneratorFg ? this.contentGeneratorFg.value as IContentText : {} as IContentText;

    var queryText =
    `Subject for the Content: ${contentText.SubjectContent ??  'Not inserted'}
     Text length: ${contentText.Length ?? 'Not selected'}
     For Medium Type: ${contentText.MediumType ?? 'Not selected'}  
     Message tone: ${contentText.MessageTone ?? 'Not selected'}`;

    this.contentGeneratorFg.get('QueryText').setValue(queryText);
   
  }
 
}