import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
 
import { using } from 'rxjs';
import { CompanyDetailsService } from 'src/app/core/services/company-details';
import { UserDetailsService } from 'src/app/core/services/user-details.service';
import { UserStateService } from 'src/app/core/services/user-state.service';
import { IUserDetails } from 'src/app/shared/IUserDetails';
import { UtilitySetting } from 'src/app/shared/UserSetting';

@Component({
  selector: 'app-userdetails-form',
  templateUrl: './userdetails-form.component.html',
  styleUrls: ['./userdetails-form.component.scss'],
})
export class UserdetailsFormComponent implements OnInit {
  public fgUserDetails: FormGroup;
  public Titles  = UtilitySetting.Titles;
  public signatureImg: string;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userDetailsService: UserDetailsService,
    private userState :UserStateService,
    private snackBar: MatSnackBar,
  ) {
    this.fgUserDetails = this.fb.group({
      Title: ['', []],
      Name: ['', []],
      Surname: ['', []],
      Gender: [0, []],
      Email: ['', []],
      Address: this.fb.group({
        Line1: ['', []],
        Line2: ['', []],
        City: ['', []],
        Postcode: ['', []],
        Country: ['', []],
      }),
      SocialMediaInformation: this.fb.group({
        Description: ['', []],
        TwitterAccount: ['', []],
        LinkedInProfile: ['', []],
        FacebookPage: ['', []],
      }),
      Phone: ['', []],
    });
  }

  ngOnInit(): void {
    this.populateUserDetailsFormWithData(this.userState.objectId);
  }

  populateUserDetailsFormWithData(objectId: string): void {

    this.userDetailsService.getUserDetails(objectId).subscribe((userDetail : IUserDetails) =>{


      this.fgUserDetails.patchValue(userDetail);
      this.signatureImg = userDetail.SignatureImg;
    }
    );

  }

  openSignatureDialog() :void
  {
   
  //   const dialogRef = this.dialog.open(SignatureDialogComponent, {
  //     data: this.signatureImg,
  //     position: { top: '30px' },
  //     width: '50%',
  //     maxWidth : '600px',
  // });

  // dialogRef.afterClosed().subscribe((signatureImage: string) => 
  // {
  //   if(signatureImage)
  //   {
  //     this.signatureImg =signatureImage;
  //   }

  // });
        

  }


  onSubmit() {
   
    let userDetail: IUserDetails = 
    { ...this.fgUserDetails.value, 
      ObjectId : this.userState.objectId,
      UserDetailId : this.userState.userDetailId,
      SignatureImg : this.signatureImg };
  
    this.userDetailsService.updateUserDetails(userDetail).subscribe(value => console.log(value));

  }
 

  saveUserDetails() {

    this.snackBar.open('Changes saved', '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration : 2500
    });
    

    let userDetails: IUserDetails;
    userDetails = { ...this.fgUserDetails.value };

    this.userDetailsService
      .addUserDetails(userDetails)
      .subscribe((value) => console.log(value));

  }
}

 