<div fxLayout="column" ffxlayoutalign="start stretch" class="container" fxLayoutGap="16px">
    <span class="title">Optimise</span>
    <span class="text">
        <p>Whilst its possible to sell the business, you may wish to consider other options. This includes to continue owning the business with and/or enacting a succession plan with management.
            To achieve the strategic price you want, listening to external & impartial expert advice is necessary and the business will need to scale in certain areas to achieve such valuations.
            In the short term, if you do wish to sell as immediately as possible, the wider market will need to be mapped to test if these buyers exist.</p>
    </span>
    
   
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutAlign="start stretch"
        fxLayoutGap="15px" style="margin-bottom: 45px;">

        <mat-card class="detail-teaser-card">
            <mat-card-header>
                <mat-card-title>Schedule a Call</mat-card-title>
                <!-- <mat-card-subtitle>Company Reference</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" style="width: 270px;">
                    <div fxLayout="column">
                        <ul>
                            <li>Schedule a 15 or 30 minute call with one of our experts.</li>

                        </ul>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                    <a  mat-flat-button color="primary" href="https://www.madealplatform.com/meetings/id/sales"
                    target="_blank">Schedule a Call</a>  
                </div>
            </mat-card-actions>
        </mat-card>





    </div>
</div>