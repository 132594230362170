import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { MaterialModule } from "../shared/material.module";
import { AddCompanyComponent } from "./add-company/add-company.component";
import { PathSelectionComponent } from './path-selection/path-selection.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { SidenavModule } from "../shared/sidenav.module";
import { PathOptionsComponent } from './path-options/path-options.component';
import { SelectedCompanyInfoComponent } from './selected-company-info/selected-company-info.component';

const routes: Routes = [
    {path : 'company-form', component: CompanyFormComponent },
    {path: 'new-company', component: AddCompanyComponent },
    {path : 'selectedcompany', component: SelectedCompanyInfoComponent },
]

@NgModule({
  declarations: [AddCompanyComponent, PathSelectionComponent, CompanyFormComponent, PathOptionsComponent, SelectedCompanyInfoComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SidenavModule
  ],
})
export class CompanyModule { }