<mat-toolbar color="primary">
    <span> M&A Deal Platform </span>
    <span class="example-spacer"></span>
  </mat-toolbar>
  <div class="page-container">
    <h2>Complete your registration</h2>
    <p>
      Review and complete any missing sections. 
    </p>
    <div fxlayout="row" fxlayoutgap="16px">
      <div fxFlex="1 1 auto" tabindex="0" [routerLink]="['company-form']">
        <mat-card appMaterialElevation>
          <mat-card-content fxlayout="column" fxlayoutalign="center center" fxlayoutgap="16px"
            class="mat-card-content">
            <img src="../../../assets/add_company.svg" width="46" height="46" alt="add new company">
            <div fxlayout="column" fxlayoutalign="center center" fxlayoutgap="6px">
              <h2>Add company details</h2>
              <span>Takes less than 2 minutes</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="1 1 auto" tabindex="0" [routerLink]="['/user/user-form']">
        <mat-card appMaterialElevation>
          <mat-card-content fxlayout="column" fxlayoutalign="center center" fxlayoutgap="16px"
            class="mat-card-content">
            <img src="../../../assets/user_details.svg" width="46" height="46" alt="add new company">
            <div fxlayout="column" fxlayoutalign="center center" fxlayoutgap="6px">
              <h2>Complete my user profile</h2>
              <span>Takes less than 2 minutes</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
