<mat-dialog-content  class="mat-typography">
   
    <h1 mat-dialog-title class="mat-h1">We will contact you shortly.</h1>
 
        <div fxLayout="column" fxLayoutAlign="space-around center">
            <img src="./../../../assets/rocket.svg" width="200" height="200" />
            <h3 class="mat-h3">Thank you for registration.</h3>
            <a  href="https://www.madealplatform.com/"  mat-raised-button color="primary" >Close</a>
        </div>

</mat-dialog-content>