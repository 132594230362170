<div fxLayout="column" ffxlayoutalign="start stretch" class="container" fxLayoutGap="16px">
    <span class="title">Assess</span>
    <span class="text">
        <p>You should also consider if selling the business is the right option for you. Its possible that you still enjoy owning/running it, but also to consider the advice of those externally who can be impartial. If you do wish to continue a sales process it is first recommended to undertake a Pain Point Assessment with us to understand which direction the business should take to grow and scale, but also which markets purchasers may come from to achieve maximum value.</p>
    </span>
    
   
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutAlign="start stretch"
        fxLayoutGap="15px" style="margin-bottom: 45px;">

        <mat-card class="detail-teaser-card">
            <mat-card-header>
                <mat-card-title>Schedule a Call</mat-card-title>
                <!-- <mat-card-subtitle>Company Reference</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" style="width: 270px;">
                    <div fxLayout="column">
                        <ul>
                            <li>Schedule a 15 or 30 minute call with one of our experts.</li>

                        </ul>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                    <a  mat-flat-button color="primary" href="https://www.madealplatform.com/meetings/id/sales"
                    target="_blank">Schedule a Call</a>  
                </div>
            </mat-card-actions>
        </mat-card>





    </div>
</div>