// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiHost:  'https://madealplatformtestapi.azurewebsites.net/api',
  port: '',
  applicationId : "133",
  instrumentationKey: 'b142bf85-5609-455f-ab49-b4a72ad18815',
  syncfusionKey : "Ngo9BigBOggjHTQxAR8/V1NGaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhfeXVURWNYVUd+WkI" 
};

     //uri: 'https://madealplatformapi.azurewebsites.net'
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 